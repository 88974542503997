// import logo from './logo.svg';
import './occupantform.css';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from "react";



export function EditPoolMainten() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  

  const [inputs, setInputs] = useState({})
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(inputs);
  const id = searchParams.get("id");
  console.log(id)

  useEffect(() => {
      getUser(id);
  }, []);

  function getUser(id) {
      const urlParams = new URLSearchParams(window.location.search);
      const id1 = urlParams.get('id');
      axios.get(`${apiUrl}/Backend/pool_maintenance_schedule_table.php?id=${id1}`).then(function (response) {
       // axios.get(`https://kjm7989.uta.cloud/Backend/pool_maintenance_schedule_table.php?id=${id1}`).then(function (response) {
          console.log(response.data);
          setInputs(response.data[0]);
          console.log(inputs);
         
      }).catch(function (error) {
          console.log(error);
        });
  }

  const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
    //   setInputs(values => ({ ...values, [name]: value }));
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));

  }
  const handleSubmit = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id1 = urlParams.get('id');
    event.preventDefault();
    console.log("Inside submit code");
    axios.put(`${apiUrl}/Backend/pool_maintenance_schedule_table.php?id=${id1}&action=edit`, inputs).then(function (response) {
        console.log(response.data);
        console.log(inputs);
        navigate('/PoolManager');

    });
}
  return (
    <div>
      <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
      <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <Header/>
      <div className="h1-heading">
        <h1>Schedule Pool Maintenance</h1>
      </div>
      <div id="container">
        <div className="form-box">
          <div className='form'>
            <form name="form" action="" method="post" onSubmit={handleSubmit}>
              <div className="two-columns">
                <fieldset>
                  <label className="form-label" for="first-name">First name<span className="form-required"> *</span></label>
                  <input value={inputs.FirstName} id="first-name" onChange={handleChange} className="form-input" type="text" name="FirstName" placeholder="Enter your first name" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="last-name">Last name<span className="form-required"> *</span></label>
                  <input value={inputs.LastName} id="last-name" onChange={handleChange} className="form-input" type="text" name="LastName" placeholder="Enter your last name" required/>
                </fieldset>
              </div>

              <fieldset>
                <label className="form-label" for="email">Email Address<span className="form-required"> *</span></label>
                <input value={inputs.PoolManagerEmailID} id="email" onChange={handleChange} className="form-input" type="email" name="PoolManagerEmailID" placeholder="Email@gmail.com" required/>
              </fieldset>

              <fieldset>
                <label className="form-label" for="maintenance">Maintenance Type<span className="form-required"> *</span></label>
                <select value={inputs.MaintenanceType} name = "MaintenanceType" onChange={handleChange} className= "form-input" id ="maintenance" required>
                  <option value="BasicPoolCleaning " className ="form-input">Basic Pool Cleaning</option>
                  <option value="HandheldPoolVaccum" className="form-input">Handheld Pool Vacuum Cleaning</option>
                  <option value="PressurePoolVaccum" className="form-input">Pressure Pool Vaccum Cleaning</option>
                  <option value="AutomaticSuction" className="form-input">Automatic Suction Vaccum Cleaning</option>
                  <option value="RoboticCleaner" className="form-input">Robotic Pool Cleaning</option>
                </select>
              </fieldset>

              <fieldset>
                  <label className="form-label" for="description">Description</label>
                  <input value={inputs.Description} id="description"onChange={handleChange} className="form-input" type="text" name="Description" placeholder="Enter Description" required/>
                </fieldset>

              <fieldset>
                <label className="form-label" for="date">Date<span className="form-required"> *</span></label>
                <input value={inputs.Date} id="date" onChange={handleChange} className="form-input" type="date" name="Date" placeholder="05/31/2001" required/>
              </fieldset>

              <fieldset>
                <label className="form-label" for="time">Time<span className="form-required"> *</span></label>
                <input value={inputs.Time} id="time" onChange={handleChange} className="form-input" type="time" name="Time" required/>
              </fieldset>

              <div className="two-columns">
                <button className="form-btn" type="submit">Update</button>
                <button className="form-btn" type="reset">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Chat/>

      <section className="footer">
        <h3> FAQs </h3>
        {/* <!-- <p> </p> --> */}
    
        <div className ="icons">
            <i className = "fa fa-facebook-f"></i>
            <i className = "fa fa-instagram"></i>
            <i className = "fa fa-address-card"></i>
            <i className = "fa fa-at"></i>
        </div>
      </section>
    </div>
  );
}

// export default poolMaintenance;
