// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { GardenVisitorModule } from '../GardenVisitorModule/GardenVisitorModule';
import { GardenMainModule } from '../GardenMainModule/GardenMainModule';
import { GardenResidentAccessModule } from '../GardenResidentAccessModule/GardenResidentAccessModule';
import { GardenTiming } from '../GardenTiming/GardenTiming';
import { GardenTimingModule } from '../GardenTimingModule/GardenTimingModule';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { EventTable } from '../BuildingManagerDashboard/EventTable';

export function GardenManager() {
    const apiUrl = process.env.REACT_APP_API_URL;
    let navigate = useNavigate()
    
    const userDat = useContext(UserContext)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')    
    const [users, setUsers] = useState([]);  
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Address1, GovtId}  = userDat.value

    useEffect(() => {
        return () => {
            getUsers();
        if (userDat) {
            console.log("inside security dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUsers() {
        axios.get(`${apiUrl}/Backend/manager.php?email=${email}`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/manage_garden_timings.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
  return (
    <div>
    
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>

        <main>
            <h1>Welcome {FirstName}</h1>
            <div className="resident-details">
                <div className ="personal-details">
                    <div className="info">
                        <div className="group-detail">
                            <label>First Name</label>
                            <div className = "detail-values">{FirstName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Last Name</label>
                            <div className = "detail-values">{LastName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Username</label>
                            <div className = "detail-values">{email}</div>
                        </div>
                        <div className="group-detail">
                            <label>Gender</label>
                            <div className = "detail-values">{Gender}</div>
                        </div>
                        <div className="group-detail">
                            <label>DOB</label>
                            <div className = "detail-values">{DOB}</div>
                        </div>
                        <div className="group-detail">
                            <label>Mobile</label>
                            <div className = "detail-values">{ContactNo}</div>
                        </div>
                        {users.map((user, index) =>
                            <React.Fragment key={index}>
                            <div className="group-detail">
                                <label>Address</label>
                                <div className="detail-values">{user.Address1}</div>
                            </div>
                            <div className="group-detail">
                                <label>Govt ID</label>
                                <div className="detail-values">{user.GovtId}</div>
                            </div>
                            </React.Fragment>
                        )}
                    </div>
                    {/* <div className="change-password">
                            <div className="pwd-btn">
                                
                                <div className='a'><Link to='/Forgot' style={{ textDecoration: 'none', color: 'white' }}>Change Password</Link></div>
                            </div>
                    </div> */}
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to={`/AddManagerDetails?email=${email}`} style={{ textDecoration: 'none', color: 'white' }}>Add Details</Link></div>
                        </div>
                    </div>
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to='/Report' style={{ textDecoration: 'none', color: 'white' }}>Generate Report</Link></div>
                        </div>
                    </div>
                </div>
            </div>

        {/* <!--- START WITH ALL TABLES FROM HERE-----> */}

            <div className="center-align">
                <div className ="occupant-list">


                    {/* <!------Garden Time------------------------------> */}

                    {/* <!------MANAGE BOOKINGS OF RESIDENTS-------------------------> */}

                    <GardenTimingModule userDat={userDat}/>
                    <EventTable userDat={userDat}/>
                    <GardenResidentAccessModule userDat={userDat}/>
                    <GardenVisitorModule userDat={userDat}/>
                    <GardenMainModule userDat={userDat}/>

                    {/* <h6>Maintenance Schedule</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Maintenance Type</div>
                            <div className='td'>Description</div>
                            <div className='td'>Date</div>
                            <div className='td'>Time</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>  
                        
                        <div className='tr'>
                            <div className='td'>1</div>
                            <div className='td'>Cleaning</div>
                            <div className='td'>Gathering different types of garbage</div>
                            <div className='td'>02/25/2023</div>
                            <div className='td'>10:00 PM</div>
                            <div className='td'><a href="">CANCEL</a></div>
                            <div className='td'><a href="">DELETE</a></div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2</div>
                            <div className='td'>Grass Maintainace</div>
                            <div className='td'>Grass cutting and watering</div>
                            <div className='td'>02/28/2023</div>
                            <div className='td'>8:00 AM</div>
                            <div className='td'><a href="">CANCEL</a></div>
                            <div className='td'><a href="">DELETE</a></div>
                        </div>
                        <div className='tr'>
                            
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/GardenMaintenance' style={{ textDecoration: 'none', color: 'white' }}>Schedule Maintenance</Link></div>   
                        </div>
                    </div> */}

                </div>    
            </div>
        </main>
        <Chat/>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = "fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div>
        </section>
    </div>

  );
}


