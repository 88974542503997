// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function PoolMainModule() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
         axios.get(`${apiUrl}/Backend/pool_maintenance_schedule_table.php`).then(function(response) {
        //axios.get(`https://kjm7989.uta.cloud/Backend/pool_maintenance_schedule_table.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteMaintenance = (id) => {
         axios.delete(`${apiUrl}/Backend/pool_maintenance_schedule_table.php/${id}/delete`).then(function (response) {
        //axios.delete(`https://kjm7989.uta.cloud/Backend/pool_maintenance_schedule_table.php/${id}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
      }

    return (
        <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        <h6>Maintenance Schedule</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Manager Email id</div>
                            <div className='td'>Maintenance Type</div>
                            <div className='td'>Description</div>
                            <div className='td'>Date</div>
                            <div className='td'>Time</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>  

                        <div className='tb'>
                        {users.map((user, index) =>
                            <div className='tr' key={index}>
                            <div className='td'>{index+1}</div>
                            <div className='td'>{user.PoolManagerEmailID}</div>
                            <div className='td'>{user.MaintenanceType}</div>
                            <div className='td'>{user.Description}</div>
                            <div className='td'>{user.Date}</div>
                            <div className='td'>{user.Time}</div>
                            <div className='td'><Link to={`/EditPoolMainten?id=${user.Id}`} >UPDATE</Link></div>
                            <div className='td'><a href="#"  onClick={() => deleteMaintenance(user.Id)}>DELETE</a></div> 
                        </div>
                        )}
                        </div>                            
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/PoolMaintenance' style={{ textDecoration: 'none', color: 'white' }}>Schedule Maintenance</Link></div>
                        </div>
                    </div>
        </main>
        </div>
    );
}