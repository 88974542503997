// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/

import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import {VehicleRegistrationForm} from '../VehicleRegistrationForm/VehicleRegistrationForm';
import { OccupantModule } from '../OccupantModule/OccupantModule';
import { MembershipTable } from './MembershipTable';
import {MaintenanceTable } from './MaintenanceTable';
import { EventRes } from './EventRes';
import {VehicleTab} from './VehicleTab';
import {ResidentEvents} from './ResidentEvents';





export function ResidentDashboard() {
    // const { email } = props.location.state;
    let navigate = useNavigate()
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const userDat = useContext(UserContext)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')   
    const [users, setUsers] = useState([]);  
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId,AptNo}  = userDat.value

    useEffect(() => {
        return () => {
        getUsers();
        if (userDat) {
            console.log("inside resident dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUsers() {
        axios.get(`${apiUrl}/Backend/residents.php?email=${email}`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/manage_garden_timings.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        
        <Header/>

        <main>
          <h1>Resident Dashboard</h1>
          <h1>Welcome {FirstName}</h1>
          <div className="resident-details">
              <div className ="personal-details">
                  <div className="info">
                      <div className="group-detail">
                          <label>First Name</label>
                          <div className = "detail-values">{FirstName}</div>
                      </div>
                      <div className="group-detail">
                          <label>Last Name</label>
                          <div className = "detail-values">{LastName}</div>
                      </div>
                      <div className="group-detail">
                          <label>Username</label>
                          <div className = "detail-values">{email}</div>
                      </div>
                      <div className="group-detail">
                          <label>Gender</label>
                          <div className = "detail-values">{Gender}</div>
                      </div>
                      <div className="group-detail">
                          <label>DOB</label>
                          <div className = "detail-values">{DOB}</div>
                      </div>
                      <div className="group-detail">
                          <label>Mobile</label>
                          <div className = "detail-values">{ContactNo}</div>
                      </div>
                      {users.map((user, index) =>
                            <React.Fragment key={index}>
                            <div className="group-detail">
                                <label>Address</label>
                                <div className="detail-values">{user.AptNo}</div>
                            </div>
                            <div className="group-detail">
                                <label>Govt ID</label>
                                <div className="detail-values">{user.GovtId}</div>
                            </div>
                            </React.Fragment>
                        )}
                  </div>
                  <div className="change-password">
                      <div className="pwd-btn">
                    
                          <div className='a'><Link to='/Forgot' style={{ textDecoration: 'none', color: 'white' }}>Change Password</Link></div>
                
                      </div>
                  </div>
                  <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to={`/AddResidentDetails?email=${email}`} style={{ textDecoration: 'none', color: 'white' }}>Add Details</Link></div>
                        </div>
                    </div>
              </div>
          </div>
  
          {/* <!--- START WITH ALL TABLES FROM HERE-----> */}
  
  
          <div className="center-align">
              <div className ="occupant-list">
  
  
                {/* <h6>Events At TDG!</h6>
  
                  <div className='table'>
                      
                      <div className='tr, th'>
                    
                              <div className='td'>#</div>
                              <div className='td'>Event Name</div>
                              <div className='td'>Location</div>
                              <div className='td'>Date</div>
                              <div className='td'>Start Time</div>
                              <div className='td'>End Time</div>
                              <div className='td'>Description</div>
                          
                      </div>
                      <div className='tr'>
                          
                        <div className='td'>1</div>
                        <div className='td'>Movie Night!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>02/26/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>04:00 PM</div>
                        <div className='td'>Black Panther</div>
                          
                      </div>
                      <div className='tr'>
                      
                        <div className='td'>2</div>
                        <div className='td'>Paint!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>03/20/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>02:00 PM</div>
                        <div className='td'>Paint murals!</div>
                          
                      </div> */}
                  {/* </div> */}
  
          {/* <!--------------Occupant List-------------> */}
  
  
  
                  {/* <h6>Occupant List</h6>
                  
                  <div className='table'>
                      
                      <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>First Name</div>
                            <div className='td'>Last Name</div>
                            <div className='td'>DOB</div>
                            <div className='td'>Gender</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>Mobile No</div>
                            <div className='td'></div>
                            <div className='td'></div>
                      </div>
                      <div className='tr'>
                            <div className='td'>1</div>
                            <div className='td'>Ana</div>
                            <div className='td'>Smith</div>
                            <div className='td'>09/23/2002</div>
                            <div className='td'>Female</div>
                            <div className='td'>ana@gmail.com</div>
                            <div className='td'>123-678-1221</div>
                            <div className='td'><a href="">EDIT</a></div>
                            <div className='td'><a href="">DELETE</a></div>
                          
                      </div>
                  </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                        <div className='a'><Link to='/OccupantForm' style={{ textDecoration: 'none', color: 'white' }}>Add Occupant</Link></div>
                      </div>
                  </div> */}
                <OccupantModule  userDat={userDat}/>
                <EventRes  userDat={userDat}/>
                <ResidentEvents userDat={userDat}/>
                <VehicleTab  userDat={userDat}/>
                <MembershipTable userDat={userDat}/>
                <MaintenanceTable userDat={userDat}/>
          {/* <!--------Register Vehicle Details----------> */}
{/*   
                  <h6>Vehicle Details</h6>
                  
                  <div className='table' >
                
                      
                      <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>Vehicle Type</div>
                            <div className='td'>License Plate</div>
                            <div className='td'></div>
                            <div className='td'></div>
                      </div>
                      <div className='tb'>  
                      {users.map((user, index) =>
                      
                        <div className='tr' key={index}>
                            
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.email}</div>
                                <div className='td'>{user.vehicle}</div>
                                <div className='td'>{user.dl}</div>
                                <div className='td'><Link to={`/EditVehicle/${user.email}`}>EDIT</Link></div>
                                <div className='td'><button onClick={() => deleteUser(user.email)}>Delete</button></div> 
                                <div className='td'><a href="">DELETE</a></div>
                            
                        </div>
                      )}
                    </div>
                  
                  </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                            <div className='a'><Link to='/VehicleRegistrationForm' style={{ textDecoration: 'none', color: 'white' }}>Add Vehicle</Link></div>
                      </div>
                  </div> */}
  
  
          {/* <!------MEMBERSHIP-------------------------> */}
  
                  {/* <h6>Manage Membership</h6>
  
                  <div className='table'>
                      
                      <div className='tr, th'>
                          
                            <div className='td'>#</div>
                            <div className='td'>Facility</div>
                            <div className='td'>No of Members</div>
                            <div className='td'>Duration</div>
                            <div className='td'>Renews On</div>
                            <div className='td'>Cost</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        
                      </div>
                      <div className='tr'>
                            <div className='td'>1</div>
                            <div className='td'>Gym</div>
                            <div className='td'>2</div>
                            <div className='td'>6 Months</div>
                            <div className='td'>06/01/2023</div>
                            <div className='td'>$500</div>
                            <div className='td'><a href="">EDIT</a></div>
                            <div className='td'><a href="">CANCEL</a></div>
                      </div>
                  </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                        <div className='a'><Link to='/MembershipForm' style={{ textDecoration: 'none', color: 'white' }}>Request Membership</Link></div>
                          
                      </div>
                  </div> */}
  
          {/* <!-------BOOKINGS-----------------------------> */}
  
  
          {/* <!-------REQUEST MAINTENANCE-----------------> */}
  
  
                  {/* <h6>Maintenance Requests</h6>
  
                    <div className='table'>
                      
                        <div className='tr, th'>
                        
                              
                                <div className='td'>#</div>
                                <div className='td'>Maintenance Type</div>
                                <div className='td'>Emergency</div>
                                <div className='td'>Date</div>
                                <div className='td'>Cost</div>
                                <div className='td'>Reservation</div>
                                <div className='td'>Time Alotted</div>
                                <div className='td'>Technician</div>
                                <div className='td'>Contact Technician</div>
                                <div className='td'></div>
                          
                              
                        </div>
                        <div className='tr'>
                              <div className='td'>1</div>
                              <div className='td'>Pest Control</div>
                              <div className='td'>5</div>
                              <div className='td'>02/25/2023</div>
                              <div className='td'>$1000</div>
                              <div className='td'>Scheduled</div>
                              <div className='td'>8:00 AM</div>
                              <div className='td'>Saul Goodman</div>
                              <div className='td'>+1 456-900-8090</div>
                              <div className='td'><a href="">CANCEL</a></div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2</div>
                            <div className='td'>Plumbing</div>
                            <div className='td'>9</div>
                            <div className='td'd>03/25/2023</div>
                            <div className='td'>--</div>
                            <div className='td'>Pending</div>
                            <div className='td'>--</div>
                            <div className='td'>--</div>
                            <div className='td'>--</div>
                            <div className='td'><a href="">CANCEL</a></div>

                        </div>
                      
                    </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                        <div className='a'><Link to='/MaintenanceRequestForm' style={{ textDecoration: 'none', color: 'white' }}>Request Maintenance</Link></div>
                      </div>
                  </div> */}
  
              </div>    
          </div>
        </main>
        <Chat/>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = "fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div>
        </section>
  
    </div>
  );
}

// export default residentDashboard;
