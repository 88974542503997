// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function EditGardenVisit() {
  const apiurl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("Id");
    console.log(id);

    useEffect(() => {
        getUser(id);
    }, []);

    function getUser(id) {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        axios.get(`${apiurl}/Backend/garden_visit.php?id=${id1}`).then(function (response) {
          //axios.get(`https://kjm7989.uta.cloud/Backend/garden_visit.php?id=${id1}`).then(function (response) {
            console.log(response.data);
            setInputs(response.data[0]);
            console.log("inside getuser garden visit edit");
            console.log(inputs);
           
        }).catch(function (error) {
            console.log(error);
          });
    }
  

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        event.preventDefault();
        console.log("Inside submit code");
        axios.put(`${apiurl}/Backend/garden_visit.php?id=${id1}&action=edit`, inputs).then(function (response) {
          //axios.put(`${apiurl}/Backend/garden_visit.php?id=${id1}&action=edit`, inputs).then(function (response) {
            console.log(response.data);
            navigate('/GardenManager');
        });
    }
  return (
    <div>
      <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
      <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <Header/>
      <div className="h1-heading">
        <h1>Register Garden Visitors</h1>
      </div>
      <div id="container">
        <div className="form-box">
          <div className='form'>
            <form name="form" action="" method="post" onSubmit={handleSubmit}>
              <div className="two-columns">
                <fieldset>
                  <label className="form-label" for="first-name">Name<span className="form-required"> *</span></label>
                  <input id="first_name" value={inputs.Name} onChange={handleChange} className="form-input" type="text" name="Name" placeholder="Enter your Name" required />
                </fieldset>

                <fieldset>
                  <label className="form-label" for="visitoremail">Visitor Email Address<span className="form-required"> *</span></label>
                  <input id="visitoremail" value={inputs.VisitorEmailId} onChange={handleChange} className="form-input" type="email" name="VisitorEmailId" placeholder="Email@gmail.com" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="residentemail">Resident Email Address<span className="form-required"> *</span></label>
                  <input id="residentemail" value={inputs.ResidentEmailId} onChange={handleChange} className="form-input" type="email" name="ResidentEmailId" placeholder="Email@gmail.com" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="apartmentNo">Garden No<span className="form-required"> *</span></label>
                  <input id="gardenNo" value={inputs.GardenNo} onChange={handleChange} className="form-input" type="text" name="GardenNo" placeholder="Garden No" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="date">Date<span className="form-required"> *</span></label>
                  <input id="date" value={inputs.Date} onChange={handleChange} className="form-input" type="date" name="Date" placeholder="05/31/2001" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="in-time">In Time<span className="form-required"> *</span></label>
                  <input id="intime" value={inputs.InTime} onChange={handleChange}  className="form-input" type="time" name="InTime" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="out-time">Out Time<span className="form-required"> *</span></label>
                  <input id="outtime" value={inputs.OutTime} onChange={handleChange} className="form-input" type="time" name="OutTime" required/>
                </fieldset>
              </div>

              <div className="two-columns">
                <button className="form-btn" type="submit">Submit</button>
                <button className="form-btn" type="reset">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Chat/>

      <section className="footer">
        <h3> FAQs </h3>
        {/* <!-- <p> </p> --> */}
    
        <div className ="icons">
            <i className = "fa fa-facebook-f"></i>
            <i className = "fa fa-instagram"></i>
            <i className = "fa fa-address-card"></i>
            <i className = "fa fa-at"></i>
        </div>
      </section>
    </div>
  );
}

// export default poolVisitForm;
