// import logo from './logo.svg';
// import './occupantform.css';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './vehicleform.css';
import {Button} from "react-native";
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';


export function EditVisitorVehicle() {
  const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
   
    const id = searchParams.get("id");
    console.log(id)

    useEffect(() => {
        getUser(id);
    }, []);

    function getUser(id) {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        axios.get(`${apiUrl}/Backend/visitorvehicle.php?id=${id1}`).then(function (response) {
         // axios.get(`https://kjm7989.uta.cloud/Backend/visitorvehicle.php?id=${id1}`).then(function (response) {
            console.log(response.data);
            setInputs(response.data[0]);
            console.log(inputs);
           
        }).catch(function (error) {
            console.log(error);
          });
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        event.preventDefault();
        console.log("Inside submit code");
         axios.put(`${apiUrl}/Backend/visitorvehicle.php?id=${id1}&action=edit`, inputs).then(function (response) {
         // axios.put(`https://kjm7989.uta.cloud/Backend/visitorvehicle.php?id=${id1}&action=edit`, inputs).then(function (response) {
            console.log(response.data);
            console.log(inputs);
            navigate('/VisitorDashboard');

        });
    }
  return (
    <div>
        <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>
        <div className="h1-heading">
          <h1>Vehicle Registration Form</h1>
        </div>
        <div id='containerv'>
          <div className="form-boxv">
            <div className='formv'>
              <form  name="form" action="" method="post" onSubmit={handleSubmit}>
                <fieldset>
                  <div className='form-label'><label className="form-label" htmlFor="email">Email Address<span className="form-required"> *</span></label></div>
                  <input id="email" value={inputs.VisitorEmailId} className="form-input" type="email" name="VisitorEmailId" placeholder="Email@gmail.com" onChange={handleChange} required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" htmlFor="veh">Vehicle Type<span className="form-required"> *</span></label>
                  <select name = "VehicleModel" value={inputs.VehicleType} className= "form-input" id ="vehicle_type" onChange={handleChange} required>
                    <option value="Car" className ="form-input">Car</option>
                    <option value="Bike" className="form-input">Bike</option>
                    <option value="Truck" className="form-input">Truck</option>
                  </select>
                </fieldset>

                <fieldset>
                  <label className="form-label" htmlFor="licenseplate">License Plate<span className="form-required"> *</span></label>
                  <input id="licenseplate" value={inputs.LicensePlate} className="form-input" type="text" name="LicensePlate" placeholder="ABC 123"onChange={handleChange}  required/>
                </fieldset>

                <div className="two-columns">
                  <button className="form-btnv" type="submit"><div className='t'>Submit</div></button>
                  <button className="form-btnv" type="reset">Cancel</button>
                </div>
            
            </form>
            </div>
          </div>
        </div>
        <Chat/>

        <section className="footer">
              <h3> FAQs </h3>
            
          
              <div className ="icons">
                  <i className = "fa fa-facebook-f"></i>
                  <i className = "fa fa-instagram"></i>
                  <i className = "fa fa-address-card"></i>
                  <i className = "fa fa-at"></i>
              </div>
          </section>
  </div>
  );
}

// export default vehicleRegistrationForm;
