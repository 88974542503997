// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';

export function VehicleTab() {
  const apiUrl = process.env.REACT_APP_API_URL;
    const userDat = useContext(UserContext);     
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId}  = userDat.value;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiUrl}/Backend/residentvehicle_table.php?email=${email}`).then(function(response) {
          //axios.get(`https://kjm7989.uta.cloud/Backend/residentvehicle_table.php?email=${email}`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteVehicle = (id) => {
      axios.delete(`${apiUrl}/Backend/residentvehicle_table.php/${id}/delete`).then(function (response) {
       // axios.delete(`https://kjm7989.uta.cloud/Backend/residentvehicle_table.php/${id}/delete`).then(function (response) {
        console.log(response.data);
        getUsers();
      });
    }
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        

                    {/* <!------Manage Events-------------------------> */}

                    <h6>Vehicle Details</h6>
                  
                  <div className='table' >
                
                      
                      <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>Vehicle Type</div>
                            <div className='td'>License Plate</div>
                            <div className='td'></div>
                            <div className='td'></div>
                      </div>
                      <div className='tb'>  
                      {users.map((user, index) =>
                      
                        <div className='tr' key={index}>
                            
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.EmailId}</div>
                                <div className='td'>{user.VehicleType}</div>
                                <div className='td'>{user.LicensePlate}</div>
                                <div className='td'><Link to={`/EditVehicleRegistration?id=${user.Id}`}>EDIT</Link></div>
                                {/* <div className='td'><button onClick={() => deleteUser(user.email)}>Delete</button></div> */}
                                <div className='td'><a href="#"  onClick={() => deleteVehicle(user.Id)}>DELETE</a></div>
                            
                        </div>
                      )}
                    </div>
                  
                  </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                            <div className='a'><Link to='/VehicleRegistrationForm' style={{ textDecoration: 'none', color: 'white' }}>Add Vehicle</Link></div>
                      </div>
                  </div>
            
        </main>
    </div>
        
  );
}