import "./residentdashboard.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';

export function VisitorEventsModules() {
  const apiUrl = process.env.REACT_APP_API_URL;

const userDat = useContext(UserContext);     
const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId}  = userDat.value;
   
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  const deleteevent = (id) => {
     axios.delete(`${apiUrl}/Backend/visitorevents.php/${id}/delete`).then(function (response) {
    //  axios.delete(`https://kjm7989.uta.cloud/Backend/visitorevents.php/${id}/delete`).then(function (response) {
      console.log(response.data);
      getUsers();
    });
  }

  function getUsers(){
    axios.get(`${apiUrl}/Backend/visitorevents.php?email=${email}`).then(function(response) {
      //axios.get(`https://kjm7989.uta.cloud/Backend/visitorevents.php?email=${email}`).then(function(response) {
        console.log(response.data);
        setUsers(response.data);
    }).catch(function (error) {
        console.log(error);
      });
      
}
  return (
    <div>
      <h6>Registered Events</h6>
      <div className="table">
        <div className="tr, th">
          <div className="td">#</div>
          <div className="td">EventId</div>
          <div className="td">Event Name</div>
          <div className="td"></div>
        </div>
        <div className='tb'>  
        {users.map((user, index) =>
        <div className="tr" key={index}>
          <div className="td">{index+1}</div>
          <div className="td">{user.EventId}</div>
          <div className="td">{user.Name}</div>
          <div className='td'><a href="#"  onClick={() => deleteevent(user.Id)}>CANCEL</a></div>
        </div>
         )}
         </div>
      </div>
    </div>
  );
}

// export default RegisteredEvents;
