
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './vehicleform.css';
import {Button} from "react-native";
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';



export function AddManagerDetails() {
  const apiurl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const userDat = useContext(UserContext);     
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId, role}  = userDat.value;

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
   
    // const email = searchParams.get("email");
    console.log(email)

    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    
    }
    const handleSubmit = (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        const email1 = urlParams.get('email');
        event.preventDefault();
        console.log("Inside submit code");
       // axios.put(`https://kjm7989.uta.cloud/Backend/managerdetails.php?email=${email}`, inputs).then(function (response) {
        axios.put(`${apiurl}/Backend/managerdetails.php?email=${email}`, inputs).then(function (response) {
            console.log(response.data);
            console.log(inputs);
            console.log(role);
            if(role=='Security Manager'){
              navigate('/SecurityManager');
            }
            else if(role=="Building Manager"){
              navigate('/BuildingManagerDashboard');
            }
            else if(role=="Pool Manager"){
              navigate('/PoolManager');
            }
            else if(role=="Garden Manager"){
              navigate('/GardenManager');
            } 
            else{
              navigate('/Login');
            }
            

        });
    }
  return (
    <div>
        <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>
        <div className="h1-heading">
          <h1>Add Details</h1>
        </div>
        <div id='containerv'>
          <div className="form-boxv">
            <div className='formv'>
              <form  name="form" action="" method="post" onSubmit={handleSubmit}>
                <fieldset>
                  <div className='form-label'><label className="form-label" htmlFor="address">Address<span className="form-required"> *</span></label></div>
                  <input id="address" className="form-input" type="text" name="address" placeholder="Address Line " onChange={handleChange} required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" htmlFor="DL">Govt Id<span className="form-required"> *</span></label>
                  <input id="dl" className="form-input" type="text" name="govtid" placeholder="ABC123"onChange={handleChange}  required/>
                </fieldset>

                <div className="two-columns">
                  <button className="form-btnv" type="submit"><div className='t'>Submit</div></button>
                  <button className="form-btnv" type="reset">Cancel</button>
                </div>
            
            </form>
            </div>
          </div>
        </div>
        <Chat/>

        <section className="footer">
              <h3> FAQs </h3>
          
              <div className ="icons">
                  <i className = "fa fa-facebook-f"></i>
                  <i className = "fa fa-instagram"></i>
                  <i className = "fa fa-address-card"></i>
                  <i className = "fa fa-at"></i>
              </div>
          </section>
  </div>
  );
}

