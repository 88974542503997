import { Route,Routes } from 'react-router-dom';
import React, { useState, useMemo, useContext } from 'react'
import {Home} from './Home/home';
import {AboutUs} from './AboutUs/AboutUs';
import Chat from './Chat/Chat';
import Nav from './Nav/Nav';
import {VisitorDashboard} from './VisitorDashboard/visitorDashboard';
import {VehicleRegistrationForm} from './VehicleRegistrationForm/VehicleRegistrationForm';
import {TennisMembershipForm} from './TennisMembershipForm/TennisMembershipForm';
import {TennisManager} from './TennisManager/TennisManager';
import {TennisMaintenance} from './TennisMaintenance/TennisMaintenance';
import {SecurityManager} from './SecurityManager/SecurityManager';
import {ResidentDashboard} from './ResidentDashboard/ResidentDashboard';
import {PoolVisitForm} from './PoolVisitForm/PoolVisitForm';
import {PoolManager} from './PoolManager/PoolManager';
import {PoolMaintenance} from './PoolMaintenance/PoolMaintenance';
import {OccupantForm} from './OccupantForm/OccupantForm';
import {NewSignup} from './NewSignup/NewSignup';
import {MembershipForm} from './MembershipForm/MembershipForm';
import {ManagerResidentForm} from './ManagerResidentForm/ManagerResidentForm';
import {ManagerVisitorForm} from './ManagerVisitorForm/ManagerVisitorForm';
import {MaintenanceRequestForm} from './MaintenanceRequestForm/MaintenanceRequestForm';
import {Login} from './Login/Login';
import {Gym} from './Gym/Gym';
import {GardenTiming} from './GardenTiming/GardenTiming';
import {GardenManager} from './GardenManager/GardenManager';
import {Forgot} from './Forgot/Forgot';
import {GardenMaintenance} from './GardenMaintenance/GardenMaintenance';
import {Floor} from './Floor/Floor';
import {EvaluateBookingForm} from './EvaluateBookingForm/EvaluateBookingForm';
import {EvaluateTennisBookingForm} from './EvaluateTennisBookingForm/EvaluateTennisBookingForm';
import {EditPoolTiming} from './EditPoolTiming/EditPoolTiming';
import {Contact} from './Contact/Contact';
import {BuildingManagerDashboard} from './BuildingManagerDashboard/BuildingManagerDashboard';
import { CreateEventForm } from './CreateEventForm/CreateEventForm';
import {Amenities} from './Amenities/Amenities';
import {AddWatchman} from './AddWatchman/AddWatchman';
import {AddVisitor} from './AddVisitor/AddVisitor';
import {AddViolation} from './AddViolation/AddViolation.js';
import { AddManagerForm } from './AddManagerForm/AddManagerForm';
import { EditGardenTiming } from './EditGardenTiming/EditGardenTiming';
import { AddHost} from './AddHost/addHost';
import { Report} from './Report/Report';
import { AddVisitorDetails } from './VisitorDetails/AddVisitorDetails';
import { VisitorVehicle } from './VisitorVehicle/VisitorVehicle';
import { VisitorEventRegistration } from './VisitorEventRegistration/VisitorEventRegistration';
import { EditVehicle } from './EditVehicle/EditVehicle';
import { WatchmanModule } from './WatchmanModule/WatchmanModule';
import { ViolationModule } from './ViolationModule/ViolationModule';
import { VisitorModuleForSecurity } from './VisitorModuleForSecurity/VisitorModuleForSecurity';
import { UserContext } from './UserContext/UserContext.js';
import { PoolTimingModule } from './PoolTimingModule/PoolTimingModule';
import { PoolVisitorModule } from './PoolVisitorModule/PoolVisitorModule';
import { PoolMainModule } from './PoolMainModule/PoolMainModule';
import { GardenVisitForm } from './GardenVisitForm/GardenVisitForm';
import { GardenVisitorModule } from './GardenVisitorModule/GardenVisitorModule';
import { GardenMainModule } from './GardenMainModule/GardenMainModule';
import { OccupantModule } from './OccupantModule/OccupantModule';
import { EditWatchman } from './EditWatchman/EditWatchman';
import { EditViolation } from './EditViolation/EditViolation';
import {EditVisitForSecurity} from './EditVisitForSecurity/EditVisitForSecurity';
import { ResidentPoolForm } from './ResidentPoolForm/ResidentPoolForm';
import {ResidentPoolAccessModule } from './ResidentPoolAccessModule/ResidentPoolAccessModule';
import { GardenResidentAccessModule } from './GardenResidentAccessModule/GardenResidentAccessModule';
import { GardenTimingModule } from './GardenTimingModule/GardenTimingModule';
import { ResidentGardenForm } from './ResidentGardenForm/ResidentGardenForm';
import { AddManagerDetails } from './AddManagerDetails/AddManagerDetails';
import { EditResidentGardenVisit } from './EditResidentGardenVisit/EditResidentGardenVisit';
import { EventTable } from './BuildingManagerDashboard/EventTable';
import { ManagerTable } from './BuildingManagerDashboard/ManagerTable';
import { ResidentTable } from './BuildingManagerDashboard/ResidentTable';
import { VisitorTable } from './BuildingManagerDashboard/VisitorTable';
import { EventRes } from './ResidentDashboard/EventRes';
import { MaintenanceTable } from './ResidentDashboard/MaintenanceTable';
import { MembershipTable } from './ResidentDashboard/MembershipTable';
import { VehicleTab } from './ResidentDashboard/VehicleTab';
import { Chart } from 'chart.js';
import { MakeEventForm } from './Events/MakeEventForm';
import { ResidentEventRegistration } from './VisitorEventRegistration/ResidentEventRegistration';
import {ResidentEvents} from './ResidentDashboard/ResidentEvents';
import { EditVehicleRegistration } from './VehicleRegistrationForm/EditVehicleRegistration';
import { EventModule } from './VisitorDashboard/EventModule';
import { VisitorVehicleModule } from './VisitorDashboard/VisitorVehicleModule';
import { VisitorEventsModules } from './VisitorDashboard/VisitorEventsModule';
import { EditVisitorVehicle } from './VisitorVehicle/EditVisitorVehicle';
import { EditPoolMainten } from './PoolMaintenance/EditPoolMainten';
import {EditResidentPoolForm} from './ResidentPoolForm/EditResidentPoolForm';
import {EditPoolForm} from './PoolVisitForm/EditPoolForm';
import { AddResidentDetails } from './VisitorDetails/AddResidentDetails';
import { EditGardenMaintenance } from './GardenMaintenance/EditGardenMaintenance';
import { EditGardenVisit } from './GardenVisitForm/EditGardenVisit';
import { EditEventsForm } from './Events/EditEventsForm';
import { WatchmanShiftModule } from './WatchmanModule/WatchmanShiftModule';
import { AddWatchmanShift } from './AddWatchman/AddWatchmanShift';
import { EditWatchmanShift } from './EditWatchman/EditWatchmanShift';
import { EditOccupantForm } from './OccupantForm/EditOccupantForm';
import {GoogleMaps} from './VisitorDashboard/GoogleMaps';

function App() {
  const userDat = useContext(UserContext)
  const [value, setValue] = useState(null)
  
  const providerValue = useMemo(() => ({ 
    value, setValue 
  }), [value, setValue])
  return (
    // <UserContext.Provider value={providerValue}>
    <UserContext.Provider value={providerValue}>
    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/" element={<Chat/>}/>
        <Route path="/" element={<Nav/>}/>
        <Route path="/aboutus" element={<AboutUs/>}/>
        <Route path="/visitorDashboard" element={<VisitorDashboard/>}/>
        <Route path="/vehicleRegistrationForm" element={<VehicleRegistrationForm/>}/>
        <Route path='/EditGardenTiming' elements={<EditGardenTiming/>}/>
        <Route path="/tennisMembershipForm" element={<TennisMembershipForm/>}/>
        <Route path="/tennisManager" element={<TennisManager/>}/>
        <Route path="/tennisMaintenance" element={<TennisMaintenance/>}/>
        <Route path="/securityManager" element={<SecurityManager/>}/>
        <Route path="/residentDashboard" element={<ResidentDashboard/>}/>
        <Route path="/poolVisitForm" element={<PoolVisitForm/>}/>
        <Route path="/poolManager" element={<PoolManager/>}/>
        <Route path="/poolMaintenance" element={<PoolMaintenance/>}/>
        <Route path="/occupantForm" element={<OccupantForm/>}/>
        <Route path="/NewSignup" element={<NewSignup/>}/>
        <Route path="/membershipForm" element={<MembershipForm/>}/>
        <Route path="/managerVisitorForm" element={<ManagerVisitorForm/>}/>
        <Route path="/managerResidentForm" element={<ManagerResidentForm/>}/>
        <Route path="/maintenanceRequestForm" element={<MaintenanceRequestForm/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/gym" element={<Gym/>}/>
        <Route path="/gardenTiming" element={<GardenTiming/>}/>
        <Route path="/gardenManager" element={<GardenManager/>}/>
        <Route path="/gardenMaintenance" element={<GardenMaintenance/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/floor" element={<Floor/>}/>
        <Route path="/evaluateTennisBookingForm" element={<EvaluateTennisBookingForm/>}/>
        <Route path="/evaluateBookingForm" element={<EvaluateBookingForm/>}/>
        <Route path="/editPoolTiming" element={<EditPoolTiming/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/buildingManagerDashboard" element={<BuildingManagerDashboard/>}/>
        <Route path='/CreateEventForm' element={<CreateEventForm/>}/>
        <Route path="/amenities" element={<Amenities/>}/>
        <Route path="/addWatchman" element={<AddWatchman/>}/>
        <Route path="/addVisitor" element={<AddVisitor/>}/>
        <Route path="/addViolation" element={<AddViolation/>}/>
        <Route path="/addManagerForm" element={<AddManagerForm/>}/>
        <Route path="/addHost" element={<AddHost/>}/>
        <Route path="/Report" element={<Report/>}/>
        <Route path='/AddVisitorDetails' element={<AddVisitorDetails/>}/>
        <Route path='/VisitorVehicle' element={<VisitorVehicle/>}/>
        <Route path='/VisitorEventRegistration' element={<VisitorEventRegistration/>}/>
        {/* <Route path='/EditVehicle' element={<EditVehicle/>}/> */}
        <Route path="/EditVehicle/:email" element={<EditVehicle/>} />
        <Route path='/WatchmanModule' element={<WatchmanModule/>}/>
        <Route path='/ViolationModule' element={<ViolationModule/>}/>
        <Route path='/VisitorModuleForSecurity' element={<VisitorModuleForSecurity/>}/>
        <Route path='/PoolTimingModule' element={<PoolTimingModule/>}/>
        <Route path='/PoolVisitorModule' element={<PoolVisitorModule/>}/>
        <Route path='/PoolMainModule' element={<PoolMainModule/>}/>
        <Route path='/GardenVisitForm' element={<GardenVisitForm/>}/>
        <Route path='/GardenVisitorModule' element={<GardenVisitorModule/>}/>
        <Route path='/GardenMainModule' element={<GardenMainModule/>}/>
        <Route path='/OccupantModule' element={<OccupantModule/>}/>
        <Route path='/EditWatchman' element={<EditWatchman/>}/>
        <Route path='/EditViolation' element={<EditViolation/>}/>
        <Route path="/EditVisitForSecurity" element={<EditVisitForSecurity/>}/>
        <Route path="/ResidentPoolForm" element={<ResidentPoolForm/>}/>
        <Route path="/ResidentPoolAccessModule" element={<ResidentPoolAccessModule/>}/>
        <Route path="/GardenResidentAccessModule" element={<GardenResidentAccessModule/>}/>
        <Route path="/GardenTimingModule" element={<GardenTimingModule/>}/>
        <Route path="/ResidentGardenForm" element={<ResidentGardenForm/>}/>
        <Route path="/AddManagerDetails" element={<AddManagerDetails/>}/>
        <Route path="/EditResidentGardenVisit" element={<EditResidentGardenVisit/>}/>
        <Route path="/EventTable" element={<EventTable/>}/>
        <Route path='/ManagerTable' element={<ManagerTable/>}/>
        <Route path='/MakeEventForm' element={<MakeEventForm/>}/> 
        <Route path='/ResidentTable' element={<ResidentTable/>}/>
        <Route path='/VisitorTable' element={<VisitorTable/>}/>
        <Route path='/vehicleTab' element={<VehicleTab/>}/>
        <Route path='/MembershipTable' element={<MembershipTable/>}/>
        <Route path='/MaintenanceTable' element={<MaintenanceTable/>}/>
        <Route path='/EventRes' element={<EventRes/>}/>
        <Route path='/ResidentEventRegistration' element={<ResidentEventRegistration/>}/>
        <Route path='/ResidentEvents' element={<ResidentEvents/>}/>
        <Route path='/EditVehicleRegistration' element={<EditVehicleRegistration/>}/>
        <Route path='/EventModule' element={<EventModule/>}/>
        <Route path='/VisitorVehicleModule' element={<VisitorVehicleModule/>}/>
        <Route path='/VisitorEventsModule' element={<VisitorEventsModules/>}/>
        <Route path='/EditVisitorVehicle' element={<EditVisitorVehicle/>}/>
        <Route path='/EditPoolMainten' element={<EditPoolMainten/>}/>
        <Route path='/EditResidentPoolForm' element={<EditResidentPoolForm/>}/>
        <Route path='/EditPoolForm' element={<EditPoolForm/>}/>
        <Route path='/AddResidentDetails' element={<AddResidentDetails/>}/>
        <Route path='/EditGardenMaintenance' element={<EditGardenMaintenance/>}/>
        <Route path='/EditGardenVisit' element={<EditGardenVisit/>}/>
        <Route path='/chat' element={<Chat/>}/>
        <Route path='/EditEventsForm' element={<EditEventsForm/>}/>
        <Route path='/WatchmanShiftModule' element={<WatchmanShiftModule/>}/>
        <Route path='/AddWatchmanShift' element={<AddWatchmanShift/>}/>
        <Route path='/EditWatchmanShift' element={<EditWatchmanShift/>}/>
        <Route path='/EditOccupantForm' element={<EditOccupantForm/>}/>
        <Route path='/GoogleMaps' element={<GoogleMaps/>}/>


    </Routes> 
    </UserContext.Provider>
  );
}

export default App;