// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';

export function CreateEventForm() {
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <Header/>
    <div className="h1-heading">
      <h1>Create Event Form</h1>
    </div>
    <div id="container">
      <div className="form-box">
        <div className='form'>
          <form  name="form" action="" method="post">

            <fieldset>
              <label className="form-label" htmlForr="eventname">Event Name<span className="form-required"> *</span></label>
              <input id="eventname" className="form-input" type="text" name="eventname" placeholder="Name of the Event" required/>
            </fieldset>

            <fieldset>
              <label className="form-label" htmlFor="venue">Venue<span className="form-required"> *</span></label>
              <input id="venue" className="form-input" type="text" name="venue" placeholder="Venue of Event" required/>
            </fieldset>

            <fieldset>
              <label className="form-label" htmlForr="date">Date<span className="form-required"> *</span></label>
              <input id="date" className="form-input" type="date" name="date" placeholder="05/31/2001" required/>
            </fieldset>

            <fieldset>
              <label className="form-label" htmlFor="time">Time<span className="form-required"> *</span></label>
              <input id="time" className="form-input" type="time" name="time" required/>
            </fieldset>

            <fieldset>
              <label className="form-label" htmlFor="description">Description<span className="form-required"> *</span></label>
              <input id="description" className="form-input" type="text" name="description" required/>
            </fieldset>

            <div className="two-columns">
              <button className="form-btn" type="Submit">Submit</button>
              <button className="form-btn" type="reset">Cancel</button>
            </div>
            
          </form>
        </div>
        
      </div>
    </div>
    <Chat/>


    <section className="footer">
        <h3> FAQs </h3>
        {/* <!-- <p> </p> --> */}
    
        <div className ="icons">
            <i className = "fa fa-facebook-f"></i>
            <i className = "fa fa-instagram"></i>
            <i className = "fa fa-address-card"></i>
            <i className = "fa fa-at"></i>
        </div>
    </section>
  </div>
  );
}

// export default bookingForm;
