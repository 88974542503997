// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import { PoolTimingModule } from '../PoolTimingModule/PoolTimingModule';
import {PoolVisitorModule} from '../PoolVisitorModule/PoolVisitorModule';
import { ResidentPoolAccessModule } from '../ResidentPoolAccessModule/ResidentPoolAccessModule';
import { PoolMainModule } from '../PoolMainModule/PoolMainModule';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {EventTable} from '../BuildingManagerDashboard/EventTable'

export function PoolManager() {
    const apiUrl = process.env.REACT_APP_API_URL;
    let navigate = useNavigate()
    
    const userDat = useContext(UserContext)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')   
    const [users, setUsers] = useState([]);     
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Address1, GovtId}  = userDat.value

    useEffect(() => {
        return () => {
        getUsers();
        if (userDat) {
            console.log("inside security dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUsers() {
        axios.get(`${apiUrl}/Backend/manager.php?email=${email}`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/manage_garden_timings.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>
        <main>
            <h1>Pool Manager Dashboard</h1>
            <h1>Welcome, {FirstName}</h1>
            <div className="resident-details">
                <div className ="personal-details">
                    <div className="info">
                        <div className="group-detail">
                            <label>First Name</label>
                            <div className = "detail-values">{FirstName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Last Name</label>
                            <div className = "detail-values">{LastName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Email ID</label>
                            <div className = "detail-values">{email}</div>
                        </div>
                        <div className="group-detail">
                            <label>Gender</label>
                            <div className = "detail-values">{Gender}</div>
                        </div>
                        <div className="group-detail">
                            <label>DOB</label>
                            <div className = "detail-values">{DOB}</div>
                        </div>
                        <div className="group-detail">
                            <label>Mobile</label>
                            <div className = "detail-values">{ContactNo}</div>
                        </div>
                        {users.map((user, index) =>
                            <React.Fragment key={index}>
                            <div className="group-detail">
                                <label>Address</label>
                                <div className="detail-values">{user.Address1}</div>
                            </div>
                            <div className="group-detail">
                                <label>Govt ID</label>
                                <div className="detail-values">{user.GovtId}</div>
                            </div>
                            </React.Fragment>
                        )}
                    </div>
                    {/* <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to='/Forgot' style={{ textDecoration: 'none', color: 'white' }}>Change Password</Link></div>
                        </div>
                    </div> */}
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to={`/AddManagerDetails?email=${email}`} style={{ textDecoration: 'none', color: 'white' }}>Add Details</Link></div>
                        </div>
                    </div>
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to='/Report' style={{ textDecoration: 'none', color: 'white' }}>Generate Report</Link></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--- START WITH ALL TABLES FROM HERE-----> */}

            <div className="center-align">
                <div className ="occupant-list">

    

                    {/* <!------Pool Time------------------------------> */}

                    <PoolTimingModule userDat={userDat}/>
                    <EventTable userDat={userDat}/>

                    {/* <!---------POOL VISITORS--------------------------------> */}

                    <PoolVisitorModule userDat={userDat}/>
                    <ResidentPoolAccessModule userDat={userDat}/>

                    {/* <!------- MAINTENANCE-----------------> */}

                    <PoolMainModule userDat={userDat}/>
                </div>
            </div>
        </main>
        
        <Chat/>
       

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> -> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = "fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div>
        </section>

    </div>
  );
}

// export default poolManager;
