// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/

import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { EventModule } from './EventModule';
import { VisitorVehicleModule } from './VisitorVehicleModule';
import { VisitorEventsModules } from './VisitorEventsModule';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import {GoogleMaps} from'../VisitorDashboard/GoogleMaps';



export function VisitorDashboard() {
    const apiUrl = process.env.REACT_APP_API_URL;
    let navigate = useNavigate()
    
    const userDat = useContext(UserContext)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')     
    const [users, setUsers] = useState([]); 
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Address1, GovtId, DL, Address}  = userDat.value

    useEffect(() => {
        return () => {
        getUsers();
        if (userDat) {
            console.log("inside security dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUsers() {
        axios.get(`${apiUrl}/Backend/visitor.php?email=${email}`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/manage_garden_timings.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>
        <main>
            <h1>Visitor Dashboard </h1>
            <h1>Welcome {email}</h1>
            <div className="resident-details">
                <div className="personal-details">
                    <div className="info">
                        <div className="group-detail">
                            <label>First Name</label>
                            <div className="detail-values">{FirstName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Last Name</label>
                            <div className="detail-values">{LastName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Username</label>
                            <div className="detail-values">{email}</div>
                        </div>
                        <div className="group-detail">
                            <label>Gender</label>
                            <div className="detail-values">{Gender}</div>
                        </div>
                        <div className="group-detail">
                            <label>DOB</label>
                            <div className="detail-values">{DOB}</div>
                        </div>
                        <div className="group-detail">
                            <label>Mobile</label>
                            <div className="detail-values">{ContactNo}</div>
                        </div>
                        {users.map((user, index) =>
                            <React.Fragment key={index}>
                            <div className="group-detail">
                                <label>Address</label>
                                <div className="detail-values">{user.Address}</div>
                            </div>
                            <div className="group-detail">
                                <label>Govt ID</label>
                                <div className="detail-values">{user.DL}</div>
                            </div>
                            </React.Fragment>
                        )}
                    </div>
                    {/* <div className="change-password">
                        <div className="pwd-btn">
            
                            <div className='a'><Link to='/Forgot' style={{ textDecoration: 'none', color: 'white' }}>Change Password</Link></div>
                        </div>
                    </div> */}
                    <div className="change-password">
                        <div className="pwd-btn">
                            {/*<input type="button" className="change-pwd-btn" onclick="" value="Change Password" />*/}
                            <div className='a'><Link to='/AddVisitorDetails' style={{ textDecoration: 'none', color: 'white' }}>Add Details</Link></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--- START WITH ALL TABLES FROM HERE-----> */}

            <div className="center-align">
                <div className="occupant-list">
                    {/* <h6>Vehicle Details</h6>
                    
                    <div className='table' >
                    
                        
                        <div className='tr, th'>
                                <div className='td'>#</div>
                                <div className='td'>Email ID</div>
                                <div className='td'>Vehicle Type</div>
                                <div className='td'>License Plate</div>
                                <div className='td'></div>
                                <div className='td'></div>
                        </div>
                        <div className='tb'>  
                        {users.map((user, index) =>
                        
                            <div className='tr' key={index}>
                                
                                    <div className='td'>{index+1}</div>
                                    <div className='td'>{user.email}</div>
                                    <div className='td'>{user.vehicle}</div>
                                    <div className='td'>{user.dl}</div>
                                    <div className='td'><a href="">EDIT</a></div>
                                    <div className='td'><a href="">DELETE</a></div>
                                
                            </div>
                        )}
                        </div>
                    
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                                <div className='a'><Link to='/VisitorVehicle' style={{ textDecoration: 'none', color: 'white' }}>Add Vehicle</Link></div>
                        </div>
                    </div> */}

                    <VisitorVehicleModule/>

        {/* <!--------Events Table From Here--------------!> */}

                    {/* <h6>Events At TDG!</h6>
                    <div className='table'>
                      
                      <div className='tr, th'>
                    
                              <div className='td'>#</div>
                              <div className='td'>EventId</div>
                              <div className='td'>Event Name</div>
                              <div className='td'>Venue</div>
                              <div className='td'>Date</div>
                              <div className='td'>Time</div>
                              <div className='td'>Description</div>
                          
                      </div>
                      <div className='tr'>
                          
                        <div className='td'>1</div>
                        <div className='td'>101</div>
                        <div className='td'>Movie Night!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>02/26/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Black Panther</div>
                          
                      </div>
                      <div className='tr'>
                      
                        <div className='td'>2</div>
                        <div className='td'>102</div>
                        <div className='td'>Paint!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>03/20/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Paint murals!</div>
                          
                      </div>
                  </div>
                  <div className="button-row">
                        <div className="btn-after-table">
                                <div className='a'><Link to='/VisitorEventRegistration' style={{ textDecoration: 'none', color: 'white' }}>Register For Event</Link></div>
                        </div>
                    </div> */}
                    <EventModule/>

        {/* <!--------Registered Events Table From Here--------------!> */}

                    {/* <h6>Registered Events</h6>
                    <div className='table'>
                      
                      <div className='tr, th'>
                    
                              <div className='td'>#</div>
                              <div className='td'>EventId</div>
                              <div className='td'>Event Name</div>
                              <div className='td'>Venue</div>
                              <div className='td'>Date</div>
                              <div className='td'>Time</div>
                              <div className='td'>Description</div>
                          
                      </div>
                      <div className='tr'>
                          
                        <div className='td'>1</div>
                        <div className='td'>101</div>
                        <div className='td'>Movie Night!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>02/26/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Black Panther</div>
                          
                      </div>
                      <div className='tr'>
                      
                        <div className='td'>2</div>
                        <div className='td'>102</div>
                        <div className='td'>Paint!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>03/20/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Paint murals!</div>
                          
                      </div>
                  </div> */}
                  <VisitorEventsModules/>
                  {/* <GoogleMaps/> */}


                </div>

                  

                <h6>Driving Directions</h6>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1678.1310008554017!2d-97.12172874191167!3d32.73223299524639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7d0e6ef229bf%3A0x70a5eb6cc76c21e1!2sMeadow%20Run%2C%20501%20Summit%20Ave%2C%20Arlington%2C%20TX%2076013!5e0!3m2!1sen!2sus!4v1676672885147!5m2!1sen!2sus" width="1400" height="800"  allowfullscreen referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </main>
        <Chat/>
    </div>
  );
}

// export default visitorDashboard;
