// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import Chat from '../Chat/Chat';
import { Header } from '../Header/header';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export function EditPoolTiming() {
  const apiurl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({})
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(inputs);

  const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }));
  }
  const handleSubmit = (event) => {
      event.preventDefault();
      console.log("Inside submit code");
      axios.put(`${apiurl}/Backend/pool_manage_timing_table.php`, inputs).then(function (response) {
      //axios.put(`https://kjm7989.uta.cloud/Backend/pool_manage_timing_table.php`, inputs).then(function (response) {
          console.log(response.data);
          navigate('/PoolManager');
      });
  }
  return (
    <div>
    
      <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
      <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <Header/>
      <div className="h1-heading">
        <h1>Pool Timings!</h1>
      </div>
      <div id="container">
        <div className="form-box">
          <div className='form'>
            <form name="form" action="" method="post" onSubmit={handleSubmit}>
              
                <fieldset>
                  <label className="form-label" for="email">Email Address<span className="form-required"> *</span></label>
                  <input id="email"  onChange={handleChange} className="form-input" type="email" name="email" placeholder="Email@gmail.com" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="poolNo">Pool No<span className="form-required"> *</span></label>
                  <input id="poolNo"  onChange={handleChange} className="form-input" type="text" name="poolno" placeholder="1" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="days">Day<span className="form-required"> *</span></label>
                  <select name = "days" onChange={handleChange} className= "form-input" id ="days" required>
                    <option value="Monday" className ="form-input">Monday</option>
                    <option value="Tuesday" className="form-input">Tuesday</option>
                    <option value="Wednesday" className="form-input">Wednesday</option>
                    <option value="Thursday" className="form-input">Thursday</option>
                    <option value="Friday" className="form-input">Friday</option>
                    <option value="Saturday" className="form-input">Saturday</option>
                    <option value="Sunday" className="form-input">Sunday</option>
                  </select>
                </fieldset>

              <div className="two-columns">
                <fieldset>
                  <label className="form-label" for="open-time">Open Time<span className="form-required"> *</span></label>
                  <input id="open-time" onChange={handleChange} className="form-input" type="time" name="opentime" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="close-time">Close Time<span className="form-required"> *</span></label>
                  <input id="close-time" onChange={handleChange} className="form-input" type="time" name="closetime" required/>
                </fieldset>
              </div>

              <div className="two-columns">
                <button className="form-btn" type="submit">SUBMIT</button>
                <button className="form-btn" type="reset">CANCEL</button>
              </div>
          
            </form>
          </div>
        </div>
      </div>
      <Chat/>
      
      <section className="footer">
          <h3> FAQs </h3>
          {/* <!-- <p> </p> --> */}
      
          <div className ="icons">
              <i className = "fa fa-facebook-f"></i>
              <i className = "fa fa-instagram"></i>
              <i className = "fa fa-address-card"></i>
              <i className = "fa fa-at"></i>
          </div>
      </section>
    </div>
  );
}

// export default editPoolTiming;
