// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';


export function EditVisitForSecurity() {
    const apiurl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();

    const id = searchParams.get("visitId");
    console.log(id)

    useEffect(() => {
        getUser(id);
    }, []);

    console.log(inputs);

    function getUser(id) {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
         axios.get(`${apiurl}/Backend/visit.php?id=${id1}`).then(function (response) {
       // axios.get(`https://kjm7989.uta.cloud/Backend/visit.php?id=${id1}`).then(function (response) {
            console.log(response.data);
            console.log(response.data);
            setInputs(response.data[0]);
            console.log("inside getuser visit edit");
            console.log(inputs);
           
        }).catch(function (error) {
            console.log(error);
          });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }
    const handleSubmit = (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        event.preventDefault();
        console.log("Inside submit code");
         axios.put(`${apiurl}/Backend/visit.php?id=${id1}&action=edit`, inputs).then(function (response) {
        //axios.put(`https://kjm7989.uta.cloud/Backend/visit.php?id=${id1}&action=edit`, inputs).then(function (response) {
            console.log(response.data);
            navigate('/SecurityManager');

        });
    }
  return (
    <div>
        <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>
        <div className="h1-heading">
            <h1>Add Visitor Log</h1>
        </div>
        <div id="container">
            <div className="form-box">
                <div className='form'>
                    <form name="form" action="" method="post" onSubmit={handleSubmit}>
                        <fieldset>
                                <label className="form-label" for="date">Visitor Email Id<span className="form-required">*</span></label>
                                <input id="email" value={inputs.VisitorEmailId} className="form-input" type="email" name="VisitorEmailId" placeholder="Enter visitor's registered email"
                                     required/>
                        </fieldset>
                        
                            <fieldset>
                                <label className="form-label" for="date">Date<span className="form-required">*</span></label>
                                <input id="date" value={inputs.VisitDate} className="form-input" type="date" name="VisitDate" placeholder="Enter Date (MM/DD/YY)"
                                   onChange={handleChange}  required/>
                            </fieldset>

                            <fieldset>
                                <label className="form-label" for="vehicletype">Vehicle Model<span className="form-required">*</span></label>
                                <input id="vehicletype" value={inputs.VehicleType} className="form-input" type="text" name="VehicleType" placeholder="Nissan Altima"
                                   onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="licenseplate">License Plate<span className="form-required">*</span></label>
                                <input id="licenseplate" value={inputs.LicensePlate} className="form-input" type="text" name="LicensePlate" placeholder="DL1234"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <div className="two-columns">
                            <fieldset>
                                <label className="form-label" for="in-time">In Time :<span className="form-required">*</span></label>
                                <input id="in-time" value={inputs.InTime} className="form-input" type="time" name="InTime" placeholder="In Time"
                                   onChange={handleChange}  required/>
                            </fieldset>

                            <fieldset>
                                <label className="form-label" for="out-time">Out Time:<span className="form-required">*</span></label>
                                <input id="out-time"  value={inputs.OutTime} className="form-input" type="time" name="OutTime" placeholder="Out Time"
                                  onChange={handleChange}  required/>
                            </fieldset>
                        </div>
                        
                        <div className="two-columns">
                            <button className="form-btn" type="submit">Submit</button>
                            <button className="form-btn" type="reset">Cancel</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}

            <div className="icons">
                <i className="fa fa-facebook-f"></i>
                <i className="fa fa-instagram"></i>
                <i className="fa fa-address-card"></i>
                <i className="fa fa-at"></i>
            </div>
        </section>

        <Chat/>

    </div>

  );
}

// export default EditVisitForSecurity;
