// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { useState } from 'react';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function AddWatchman() {
    const apiurl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
   // const email = searchParams.get("email");
    // const role = searchParams.get("role");
    //inputs.email = email;
    // inputs.role = role;
    console.log(inputs);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Inside submit code");
       axios.post(`${apiurl}/Backend/watchman.php`, inputs).then(function (response) {
        //axios.post(`https://kjm7989.uta.cloud/Backend/watchman.php`, inputs).then(function (response) {
            console.log(response.data);
            navigate('/SecurityManager');

        });
    }
  return (
    <div>
        <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

        <Header/>
        <div className="h1-heading">
            <h1>Watchman Data Creation</h1>
        </div>
        <div id="container">
            <div className="form-box">
                <div className='form'>
                    <form name="form" action="" method="post" onSubmit={handleSubmit}>
                        <div className="two-columns">

                            <fieldset>
                            <label className="form-label" htmlFor="first-name">First name<span className="form-required"> *</span></label>
                            <input id="first-name" className="form-input" type="text" name="firstname" placeholder="First name" onChange={handleChange} required/>
                            </fieldset>

                            <fieldset>
                            <label className="form-label" htmlFor="last-name">Last name<span className="form-required"> *</span></label>
                            <input id="last-name" className="form-input" type="text" name="lastname" placeholder="Last name" onChange={handleChange} required/>
                            </fieldset>
                        </div>

                            <fieldset>
                                <label className="form-label" for="duty">Duty Type<span className="form-required"> </span></label>
                                <input id="duty" className="form-input" type="text" name="duty" placeholder="Enter Duty Type"
                                    onChange={handleChange} required/>
                            </fieldset>

                            <fieldset>
                                <label className="form-label" for="ssn">SSN<span className="form-required"> *</span></label>
                                <input id="ssn" className="form-input" type="text" name="ssn" placeholder="Enter SSN"
                                    onChange={handleChange} required/>
                            </fieldset>

                            <fieldset>
                                <label className="form-label" for="salary">Salary<span className="form-required"> *</span></label>
                                <input id="salary" className="form-input" type="text" name="salary" placeholder="Enter Salary"
                                  onChange={handleChange}  required/>
                            </fieldset>
                        

                        <div className="two-columns">
                            <button className="form-btn" type="submit">Submit</button>
                            <button className="form-btn" type="reset">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}

            <div className="icons">
                <i className="fa fa-facebook-f"></i>
                <i className="fa fa-instagram"></i>
                <i className="fa fa-address-card"></i>
                <i className="fa fa-at"></i>
            </div>
        </section>
        <Chat/>
    </div>
  );
}

// export default addWatchman;
