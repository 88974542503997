// import logo from './logo.svg';
// import './occupantform.css';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './vehicleform.css';
import { useState, useEffect } from "react";
import {Button} from "react-native";
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import { useParams } from 'react-router-dom';

export function EditVehicle() {
  const apiurl = process.env.REACT_APP_API_URL;
  const { email } = useParams()
  const navigate = useNavigate();
 

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
   // const { email } = useParams();
     //const email = searchParams.get("email");
    //const email ="arya@yahoo.com";
    console.log(email)
    //const role = searchParams.get("role");
    inputs.email = email;
    // inputs.role = role;
    console.log(inputs);
    useEffect(() => {
        getUser();
    }, []);

    function getUser() {
         axios.get(`${apiurl}/Backend/addVehicle.php?email=${email}`).then(function (response) {
          //axios.get(`https://kjm7989.uta.cloud/Backend/addVehicle.php?email=${email}`).then(function (response) {
            console.log(response.data);
            setInputs(response.data);
            console.log("inside getuser");
            console.log(inputs);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`${apiurl}/Backend/addVehicle.php`, inputs).then(function (response) {
          //axios.put(`https://kjm7989.uta.cloud/Backend/addVehicle.php`, inputs).then(function (response) {
            console.log(response.data);
            navigate('/ResidentDashboard');
        });

    }
    
    return (
        <div>
            <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
            <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <Header/>
            <div className="h1-heading">
              <h1>Edit Vehicle</h1>
            </div>
            <div id='containerv'>
              <div className="form-boxv">
                <div className='formv'>
                  <form  name="form" action="" method="post" onSubmit={handleSubmit}>
                    <fieldset>
                      <div className='form-label'><label className="form-label" htmlFor="email">Email Address<span className="form-required"> *</span></label></div>
                      <input  value = {inputs.email} id="email" className="form-input" type="email" name="email" onChange={handleChange} required/>
                    </fieldset>
    
                    <fieldset>
                      <label className="form-label" htmlFor="veh">Vehicle Type<span className="form-required"> *</span></label>
                      <select  value = {inputs.vehicle} name = "vehicle_type" className= "form-input" id ="vehicle_type" onChange={handleChange} required>
                        <option value="Car" className ="form-input">Car</option>
                        <option value="Bike" className="form-input">Bike</option>
                        <option value="Truck" className="form-input">Truck</option>
                      </select>
                    </fieldset>
    
                    <fieldset>
                      <label className="form-label" htmlFor="licenseplate">License Plate<span className="form-required"> *</span></label>
                      <input value = {inputs.dl} id="licenseplate" className="form-input" type="text" name="licenseplate" placeholder="ABC 123"onChange={handleChange}  required/>
                    </fieldset>
    
                    <div className="two-columns">
                      <button className="form-btnv" type="submit"><div className='t'>Submit</div></button>
                      <button className="form-btnv" type="reset">Cancel</button>
                    </div>
                
                </form>
                </div>
              </div>
            </div>
            <Chat/>
    
            <section className="footer">
                  <h3> FAQs </h3>
                
              
                  <div className ="icons">
                      <i className = "fa fa-facebook-f"></i>
                      <i className = "fa fa-instagram"></i>
                      <i className = "fa fa-address-card"></i>
                      <i className = "fa fa-at"></i>
                  </div>
              </section>
      </div>
      );
    }
    
  


// export default vehicleRegistrationForm;
