// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './log-for.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import tdg1 from "../images/TDG.png";
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'


export function Login() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [isLoggedin, setLogin] = React.useState(false)
    const { value, setValue } = useContext(UserContext)
    const navigate = useNavigate()
    const loginHandle = async (e) => {
        console.log(email);
        console.log(password);
        e.preventDefault()
        let config = { method: 'POST', email: email, password: password }

    try {
        await axios
          // .post('http://localhost/backend/login.php', config)
          .post(`${apiUrl}/Backend/login.php`, config)
          .then((response) => {
            console.log(response.data);
            
            // const dataString = response.data.toString();
            // const startIndex = dataString.indexOf('{"userData":{');
            // const endIndex = dataString.lastIndexOf('}') + 1;
            // const userData = JSON.parse(dataString.substring(startIndex, endIndex)).userData;
            // const role = userData.role;
            
            //setLogin(true);
            if (Object.keys(response.data.userData).length > 0){
             
              let obj = {...response.data.userData};
              obj.isLogged = true;
              console.log(obj.isLogged)
              setValue(obj);
      
              // obj = {...obj, isLogged: isLoggedin};
              // setValue(obj);
              console.log("inside if object>0");
              console.log(obj);
              console.log(response.data.userData.role)
              console.log(response.data.userData.email)
              setEmail(response.data.userData.email)
              const role = response.data.userData.role
              if(role=="Resident"){
                navigate('/ResidentDashboard');
                //navigate('/ResidentDashboard', { state: { email: response.data.userData.email } });
              }
              else if(role=="Visitor"){
                navigate('/VisitorDashboard');
              }
              else if(role=="Building Manager"){
                navigate('/BuildingManagerDashboard');
              }
              else if(role=="Security Manager"){
                navigate('/SecurityManager');
              }
              else if(role=="Pool Manager"){
                navigate('/PoolManager');
              }
              else if(role=="Garden Manager"){
                navigate('/GardenManager');
              } 
            }
          })
          .catch((error) => {
            console.log('no authorization', error)
            alert("Invalid Credentials!");
          })
      } catch (error) {
        setTimeout(() => {
          setError('Invalid credentials...')
        }, 10)
      }
    }
  return (
    <div>
        <Header/>
        
        <div className="logfor-containers">
            <h1 style={{ color: "darkslategray" }} className='login-h1' >
                Log in here
            </h1>
            <p style={{ color: "black" }} >
                Almost there...Please log in here with your credentials
            </p>
            <div className="logfor-box">
                <div className="logfor-left">
                    {/* <!-- Code for the login form --> */}
                    <form action="" className="form" onSubmit={loginHandle}>
                        <h2 style={{ color: "darkslategray", textAlign: "center", fontSize: "2rem", margin:"10px"}}>
                            Login
                        </h2>
                            <div className="logfor-input-group">
                                <label ><b>UserName</b></label>
                                <input type="text" placeholder="Email ID" onChange={(e) => setEmail(e.target.value)} className="box"/>
                            </div>
                            <div className="logfor-input-group">
                                <label><b>Password</b></label>
                                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} className="box"/>
                            </div>
                            {/* <!-- Submit Button of login form --> */}
                            <div className="forget"><Link to='/Forgot' style={{ textDecorationColor: "black" , color: "black"}}>Forgot password</Link></div>
                        <button className='loginbtn'type="submit">LOGIN</button>                       
                    </form>
                    
                    {/* <!-- End of the login form --> */}
                    <h3 style={{ color: "darkslategray"}}>Dont have an account?....<Link to="/NewSignup" style={{ textDecorationColor: "black" , color: "black"}}>Signup here</Link></h3>
                    
        {/* <div className="logfor-input-group">  
            <button><Link  to ='/ResidentDashboard' style={{ textDecoration: 'none', color: 'white' }}>Resident</Link></button>
            <button><Link to ='/VisitorDashboard' style={{ textDecoration: 'none', color: 'white' }}>Visitor</Link></button>
            <button><Link to ='/TennisManager' style={{ textDecoration: 'none', color: 'white' }}>Tennis Manager</Link></button>
            <button><Link to ='/PoolManager' style={{ textDecoration: 'none', color: 'white' }}>Pool Manager</Link></button>
            <button><Link to ='/BuildingManagerDashboard' style={{ textDecoration: 'none', color: 'white' }}>Building Manager</Link></button>
            <button><Link to ='/GardenManager' style={{ textDecoration: 'none', color: 'white' }}>Garden Manager</Link></button>
            <button><Link to ='/SecurityManager' style={{ textDecoration: 'none', color: 'white' }}>Security Manager</Link></button>   
           
        </div> */}

                </div>
                
                {/* <!-- Right side image of login form --> */}
                <div className="logfor-right">
                       {/* <img src="TDG-logos.jpeg" width="500" height="500"/>
                       <img src={tdg1}/> */}
                </div>
            </div>
        </div>


          {/* <!-- code for the footer icons and FAQs --> */}
          <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = " fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div> 
        </section>
        {/* <!-- End of the footer --> */}
    </div>
  );
}

// export default login;
