import './Chat.css';
import React from 'react';
import { Link } from 'react-router-dom';

const openForm = () =>{
    // document.getElementById("myForm").style.display = "block";
  window.location.href = 'http://localhost:3004/chat.html';
  //<iframe src="http://localhost:3002/chat.html" width="100%" height="500" />
    //window.open('http://localhost:3004/chat.html', '_blank', 'width=800,height=600');
}
const closeForm = () =>{
    document.getElementById("myForm").style.display = "none";
}

function Chat() {
    
  return (
    <div>
      
    
    <button className="open-button" onClick={openForm}>Chat</button>

    {/* <div className="chat-popup" id="myForm">
      <form action="/action_page.php" className="form-container">
        <h1>Chast</h1>
    
        <label htmlFor="msg"><b>Message</b></label>
        <textarea placeholder="Type message.." name="msg" required></textarea>
    
        <button type="submit" className="btn">Send</button>
        <button type="button" className="btn cancel" onClick={closeForm}>Close</button>
      </form>
    </div>
    <iframe src="/index.html" width="100%" height="500" /> */}
  </div>
  );
}

export default Chat;
