// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function ViolationModule() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiUrl}/Backend/violation.php`).then(function(response) {
          //  axios.get(`https://kjm7989.uta.cloud/Backend/violation.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteViolation = (id) => {
         axios.delete(`${apiUrl}/Backend/violation.php/${id}/delete`).then(function (response) {
        //axios.delete(`https://kjm7989.uta.cloud/Backend/violation.php/${id}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
      }

  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        
                    
                    <h6>Resident Violation History</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>Violation Id</div>
                            <div className='td'>First name</div>
                            <div className='td'>Last Name</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>Date of Violation</div>
                            <div className='td'>Violation Description</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>
                        <div className='tb'>
                        {users.map((user, index) =>
                        <div className='tr'key={index}>
                            <div className='td'>{user.violationId}</div>
                            <div className='td'>{user.FirstName}</div>
                            <div className='td'>{user.LastName}</div>
                            <div className='td'>{user.ResidentEmailId}</div>
                            <div className='td'>{user.ViolationDate}</div>
                            <div className='td'>{user.ViolationDescription}</div>
                            <div className='td'><Link to={`/EditViolation?id=${user.violationId}`} >UPDATE</Link></div>
                            <div className='td'><a href="#"  onClick={() => deleteViolation(user.violationId)}>DELETE</a></div>
                            {/* <div className='td'><a href="">DELETE</a></div> */}
                        </div>
                        )}
                        </div>
                    </div>
                        
                    <div className="button-row">
                        <div className="btn-after-table">
                            {/* <a href="addViolation.html" type="button" className="occupant-btn">Add Violation</a> */}
                            <div className='a'><Link to='/AddViolation' style={{ textDecoration: 'none', color: 'white' }}>Add Violation</Link></div>
                        </div>
                    </div>
           
        </main>
    </div>
        
  );
}