// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';


export function MembershipTable() {
  const apiUrl = process.env.REACT_APP_API_URL;
    const userDat = useContext(UserContext);     
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId}  = userDat.value;
   
    console.log("Memebership table email value:")
    console.log(email)
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers(email);
    }, []);

    function getUsers(){
       axios.get(`${apiUrl}/Backend/membership_table.php?email=${email}`).then(function(response) {
      //  axios.get(`https://kjm7989.uta.cloud/Backend/membership_table.php?email=${email}`).then(function(response) {
          console.log(response.data);
          setUsers(response.data);
      }).catch(function (error) {
          console.log(error);
        });
  }
  const deleteMembership = (id) => {
    axios.delete(`${apiUrl}/Backend/membership_table.php/${id}/delete`).then(function (response) {
    //  axios.delete(`https://kjm7989.uta.cloud/Backend/membership_table.php/${id}/delete`).then(function (response) {
      console.log(response.data);
      getUsers();
    });
  }
    
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        

                    {/* <!------Manage Events-------------------------> */}

                    <h6>Manage Membership</h6>
  
                    <div className='table'>
                        
                        <div className='tr, th'>
                            
                              <div className='td'>#</div>
                              <div className='td'>Facility</div>
                              <div className='td'>No of Members</div>
                              <div className='td'>Duration</div>
                              <div className='td'>Start Date</div>
                              <div className='td'></div>
                          
                        </div>
                        <div className='tb'>
                        {users.map((user, index) =>
                        <div className='tr' key={index}>
                              <div className='td'>{index+1}</div>
                              <div className='td'>{user.Facility}</div>
                              <div className='td'>{user.NoOfResidents}</div>
                              <div className='td'>{user.Duration}</div>
                              <div className='td'>{user.StartDate}</div>
                              <div className='td'><a href="#"  onClick={() => deleteMembership(user.Id)}>CANCEL</a></div>
                        </div>
                        )}
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                          <div className='a'><Link to='/MembershipForm' style={{ textDecoration: 'none', color: 'white' }}>Request Membership</Link></div>
                            
                        </div>
                    </div>
            
        </main>
    </div>
        
  );
}