// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';


export function ResidentTable() {
    const apiurl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiurl}/Backend/residents.php`).then(function(response) {
       // axios.get(`https://kjm7989.uta.cloud/Backend/residents.php`).then(function (response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteResident = (email) => {
        axios.delete(`${apiurl}/Backend/residents.php/${email}/delete`).then(function (response) {
       // axios.delete(`https://kjm7989.uta.cloud/Backend/residents.php/${email}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
      }
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        

                    {/* <!------Manage Residents-------------------------> */}

                    <h6>Resident Details</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#               </div>
                            <div className='td'>First Name      </div>
                            <div className='td'>Last Name       </div>
                            <div className='td'>Email ID        </div>
                            <div className='td'>DOB             </div>
                            <div className='td'>Govt Id         </div>
                            <div className='td'>Apt No          </div>
                            <div className='td'>Contact No      </div>
                            <div className='td'></div>
                        </div>
                        <div className='tb'>
                            {users.map((user, index) =>
                            <div className='tr' key={index}>
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.FirstName}</div>
                                <div className='td'>{user.LastName}</div>
                                <div className='td'>{user.Emailid}</div>
                                <div className='td'>{user.DOB}</div>
                                <div className='td'>{user.GovtId}</div>
                                <div className='td'>{user.AptNo}</div>
                                <div className='td'>{user.ContactNo}</div>
                                {/* <div className='td'><a href="">UPDATE</a></div> */}
                                <div className='td'><a href="#" onClick={() => deleteResident(user.Emailid)}>DELETE</a></div>
                            </div>
                             )}
                        </div>
                       
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/ManagerResidentForm' style={{ textDecoration: 'none', color: 'white' }}>Add Resident</Link></div>
                            
                        </div>
                    </div>



        </main>
    </div>
        
  );
}