import { useMemo } from "react";
import "./GoogleMaps.css"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export function GoogleMaps() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAy6A4cgjbeC6Vs7vG4bGk7Gj0eSYfZyD4",
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map />;
}

function Map() {
  const center = useMemo(() => ({ lat: 32.7323997306833, lng: -97.12063791609106 }), []);
  return (
    <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
      <Marker position={center} />
    </GoogleMap>
  );
}



