// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import { EventTable } from './EventTable';
import{ResidentTable} from './ResidentTable';
import { VisitorTable } from './VisitorTable';
import { ManagerTable } from './ManagerTable';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

export function BuildingManagerDashboard() {
    const apiUrl = process.env.REACT_APP_API_URL;
    let navigate = useNavigate()
    
    const userDat = useContext(UserContext)
    const [user, setUser] = useState('')
    const [role, setRole] = useState('')   
    const [users, setUsers] = useState([]);  
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Address1, GovtId}  = userDat.value

    useEffect(() => {
        return () => {
            getUsers();
        if (userDat) {
            console.log("inside security dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUsers() {
        axios.get(`${apiUrl}/Backend/manager.php?email=${email}`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/manage_garden_timings.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Header/>

        <main>
            <h1>Building Manager Dashboard</h1>
            <h1>Welcome {FirstName}</h1>
            <div className="resident-details">
                <div className ="personal-details">
                    <div className="info">
                        <div className="group-detail">
                            <label>First Name</label>
                            <div className = "detail-values">{FirstName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Last Name</label>
                            <div className = "detail-values">{LastName}</div>
                        </div>
                        <div className="group-detail">
                            <label>Username</label>
                            <div className = "detail-values">{email}</div>
                        </div>
                        <div className="group-detail">
                            <label>Gender</label>
                            <div className = "detail-values">{Gender}</div>
                        </div>
                        <div className="group-detail">
                            <label>DOB</label>
                            <div className = "detail-values">{DOB}</div>
                        </div>
                        <div className="group-detail">
                            <label>Mobile</label>
                            <div className = "detail-values">{ContactNo}</div>
                        </div>
                        {users.map((user, index) =>
                            <React.Fragment key={index}>
                            <div className="group-detail">
                                <label>Address</label>
                                <div className="detail-values">{user.Address1}</div>
                            </div>
                            <div className="group-detail">
                                <label>Govt ID</label>
                                <div className="detail-values">{user.GovtId}</div>
                            </div>
                            </React.Fragment>
                        )}
                    </div>
                    {/* <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to='/Forgot' style={{ textDecoration: 'none', color: 'white' }}>Change Password</Link></div>
                        </div>
                    </div> */}
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to={`/AddManagerDetails?email=${email}`} style={{ textDecoration: 'none', color: 'white' }}>Add Details</Link></div>
                        </div>
                    </div>
                    <div className="change-password">
                        <div className="pwd-btn">
                        <div className='a'><Link to='/Report' style={{ textDecoration: 'none', color: 'white' }}>Generate Report</Link></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--- START WITH ALL TABLES FROM HERE-----> */}

            <div className="center-align">
                <div className ="occupant-list">

                    
                    
                {/* <!----------Event List---------------------> */}

                <EventTable userDat={userDat}/>
                    {/* <h6>Weekly Events At TDG!</h6>
                    <div className='table'>
                      
                      <div className='tr, th'>
                    
                              <div className='td'>#</div>
                              <div className='td'>EventId</div>
                              <div className='td'>Event Name</div>
                              <div className='td'>Venue</div>
                              <div className='td'>Date</div>
                              <div className='td'>Time</div>
                              <div className='td'>Description</div>
                          
                      </div>
                      <div className='tr'>
                          
                        <div className='td'>1</div>
                        <div className='td'>101</div>
                        <div className='td'>Movie Night!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>02/26/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Black Panther</div>
                          
                      </div>
                      <div className='tr'>
                      
                        <div className='td'>2</div>
                        <div className='td'>102</div>
                        <div className='td'>Paint!</div>
                        <div className='td'>Club House</div>
                        <div className='td'>03/20/2023</div>
                        <div className='td'>12:00 PM</div>
                        <div className='td'>Paint murals!</div>
                          
                      </div>
                  </div>
                  <div className="button-row">
                        <div className="btn-after-table">
                                <div className='a'><Link to='/CreateEventForm' style={{ textDecoration: 'none', color: 'white' }}>Create Event</Link></div>
                        </div>
                    </div> */}

                    {/* <!----------RESIDENT List---------------------> */}

                    {/* <h6>Resident Details</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#               </div>
                            <div className='td'>First Name      </div>
                            <div className='td'>Last Name       </div>
                            <div className='td'>Email ID        </div>
                            <div className='td'>DOB             </div>
                            <div className='td'>Govt Id         </div>
                            <div className='td'>Apt No          </div>
                            <div className='td'>Contact No      </div>
                            <div className='td'>Apartment Type  </div>
                            <div className='td'>No of Occupants </div>
                            <div className='td'>                </div>
                            <div className='td'>                </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                   </div>
                            <div className='td'>res                                 </div>
                            <div className='td'>las                                 </div>
                            <div className='td'>res.las@gmail.com                   </div>
                            <div className='td'>09/09/1960                          </div>
                            <div className='td'>***-***-6755                        </div>
                            <div className='td'>235                                 </div>
                            <div className='td'>111-345-8900                        </div>
                            <div className='td'>Studio                              </div>
                            <div className='td'>1                                   </div>
                            <div className='td'><a href="">EDIT</a>                 </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                  </div>
                            <div className='td'>rickon                             </div>
                            <div className='td'>stark                              </div>
                            <div className='td'>rickon.stark@gmail.com             </div>
                            <div className='td'>09/08/1960                         </div>
                            <div className='td'>***-***-1256                       </div>
                            <div className='td'>205                                </div>
                            <div className='td'>110-305-7900                       </div>
                            <div className='td'>Town House                         </div>
                            <div className='td'>4                                  </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/ManagerResidentForm' style={{ textDecoration: 'none', color: 'white' }}>Add Resident</Link></div>
                            
                        </div>
                    </div> */}
                    <ResidentTable userDat={userDat}/>



                    {/* <!---------VISITORS--------------------------------> */}

                    {/* <h6>Visitor Details</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#                 </div>
                            <div className='td'>First Name        </div>
                            <div className='td'>Last Name         </div>
                            <div className='td'>Visitor Email ID  </div>
                            <div className='td'>Govt ID           </div>
                            <div className='td'>Contact No        </div>
                            <div className='td'>Vehicle Type      </div>
                            <div className='td'>License Plate     </div>
                            <div className='td'>                  </div>
                            <div className='td'>                  </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                  </div>
                            <div className='td'>Theon                              </div>
                            <div className='td'>Greyjoy                            </div>
                            <div className='td'>theon@gmail.com                    </div>
                            <div className='td'>***-***-9000                       </div>
                            <div className='td'>345-678-1234                       </div>
                            <div className='td'>Car                                </div>
                            <div className='td'>ABC 900                            </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/ManagerVisitorForm' style={{ textDecoration: 'none', color: 'white' }}>Add Visitor</Link></div>
                        </div>
                    </div> */}
                     <VisitorTable userDat={userDat}/>
                     <ManagerTable userDat={userDat}/>


                    {/* <!----------Security Manager-------------------> */}
{/* 
                    <h6>Security Manager List</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#               </div>
                            <div className='td'>First Name      </div>
                            <div className='td'>Last Name       </div>
                            <div className='td'>Email ID        </div>
                            <div className='td'>DOB             </div>
                            <div className='td'>Govt Id         </div>
                            <div className='td'>Contact No      </div>
                            <div className='td'>Adress Line 1   </div>
                            <div className='td'>Adress Line 2   </div>
                            <div className='td'>Manager Type    </div>
                            <div className='td'>                </div>
                            <div className='td'>                </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                   </div>
                            <div className='td'>Dwight                              </div>
                            <div className='td'>Schrute                             </div>
                            <div className='td'>dwight@gmail.com                    </div>
                            <div className='td'>08/08/1960                          </div>
                            <div className='td'>***-***-6055                        </div>
                            <div className='td'>123-000-5643                        </div>
                            <div className='td'>670 W Abram St                      </div>
                            <div className='td'>Arlington, Texas                    </div>
                            <div className='td'>Security                            </div>
                            <div className='td'><a href="">EDIT</a>                 </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2                                  </div>
                            <div className='td'>Michael                            </div>
                            <div className='td'>Scott                              </div>
                            <div className='td'>scott@gmail.com                    </div>
                            <div className='td'>02/03/1960                         </div>
                            <div className='td'>***-***-6155                       </div>
                            <div className='td'>123-000-5893                       </div>
                            <div className='td'>671 W Abram St                     </div>
                            <div className='td'>Arlington, Texas                   </div>
                            <div className='td'>Security                           </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/AddManagerForm' style={{ textDecoration: 'none', color: 'white' }}>Add Manager</Link></div>
            
                        </div>
                    </div> */}


                    {/* <!----------Garden Manager------------------> */}

                    {/* <h6>Garden Manager List</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#             </div>
                            <div className='td'>First Name    </div>
                            <div className='td'>Last Name     </div>
                            <div className='td'>Email ID      </div>
                            <div className='td'>DOB           </div>
                            <div className='td'>Govt Id       </div>
                            <div className='td'>Contact No    </div>
                            <div className='td'>Adress Line 1 </div>
                            <div className='td'>Adress Line 2 </div>
                            <div className='td'>Manager Type  </div>
                            <div className='td'>              </div>
                            <div className='td'>              </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                  </div>
                            <div className='td'>Dwight                             </div>
                            <div className='td'>Schrute                            </div>
                            <div className='td'>dwight@gmail.com                   </div>
                            <div className='td'>08/08/1960                         </div>
                            <div className='td'>***-***-6055                       </div>
                            <div className='td'>123-000-5643                       </div>
                            <div className='td'>670 W Abram St                     </div>
                            <div className='td'>Arlington, Texas                   </div>
                            <div className='td'>Garden                             </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2                                   </div>
                            <div className='td'>Michael                             </div>
                            <div className='td'>Scott                               </div>
                            <div className='td'>scott@gmail.com                     </div>
                            <div className='td'>02/03/1960                          </div>
                            <div className='td'>***-***-6155                        </div>
                            <div className='td'>123-000-5893                        </div>
                            <div className='td'>671 W Abram St                      </div>
                            <div className='td'>Arlington, Texas                    </div>
                            <div className='td'>Garden                              </div>
                            <div className='td'><a href="">EDIT</a>                 </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/AddManagerForm' style={{ textDecoration: 'none', color: 'white' }}>Add Manager</Link></div>
                            
                        </div>
                    </div> */}

                    {/* <!---------------Pool Manager-----------> */}

                    {/* <h6>Pool Manager List</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>First Name</div>
                            <div className='td'>Last Name</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>DOB</div>
                            <div className='td'>Govt Id</div>
                            <div className='td'>Contact No</div>
                            <div className='td'>Adress Line 1</div>
                            <div className='td'>Adress Line 2</div>
                            <div className='td'>Manager Type</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1</div>
                            <div className='td'>Dwight</div>
                            <div className='td'>Schrute</div>
                            <div className='td'>dwight@gmail.com</div>
                            <div className='td'>08/08/1960</div>
                            <div className='td'>***-***-6055</div>
                            <div className='td'>123-000-5643</div>
                            <div className='td'>670 W Abram St</div>
                            <div className='td'>Arlington, Texas</div>
                            <div className='td'>Pool</div>
                            <div className='td'><a href="">EDIT</a></div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2</div>
                            <div className='td'>Michael</div>
                            <div className='td'>Scott</div>
                            <div className='td'>scott@gmail.com</div>
                            <div className='td'>02/03/1960</div>
                            <div className='td'>***-***-6155</div>
                            <div className='td'>123-000-5893</div>
                            <div className='td'>671 W Abram St</div>
                            <div className='td'>Arlington, Texas</div>
                            <div className='td'>Pool</div>
                            <div className='td'><a href="">EDIT</a></div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/AddManagerForm' style={{ textDecoration: 'none', color: 'white' }}>Add Manager</Link></div>
                        </div>
                    </div> */}

                    {/* <!--------------Tennis Court Manager-----------> */}

                    {/* <h6>Tennis Court Manager List</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>First Name</div>
                            <div className='td'>Last Name</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>DOB</div>
                            <div className='td'>Govt Id</div>
                            <div className='td'>Contact No</div>
                            <div className='td'>Adress Line 1</div>
                            <div className='td'>Adress Line 2</div>
                            <div className='td'>Manager Type</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                 </div>
                            <div className='td'>Dwight                            </div>
                            <div className='td'>Schrute                           </div>
                            <div className='td'>dwight@gmail.com                  </div>
                            <div className='td'>08/08/1960                        </div>
                            <div className='td'>***-***-6055                      </div>
                            <div className='td'>123-000-5643                      </div>
                            <div className='td'>670 W Abram St                    </div>
                            <div className='td'>Arlington, Texas                  </div>
                            <div className='td'>Tennis Court                      </div>
                            <div className='td'><a href="">EDIT</a>               </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2                                 </div>
                            <div className='td'>Michael                           </div>
                            <div className='td'>Scott                             </div>
                            <div className='td'>scott@gmail.com                   </div>
                            <div className='td'>02/03/1960                        </div>
                            <div className='td'>***-***-6155                      </div>
                            <div className='td'>123-000-5893                      </div>
                            <div className='td'>671 W Abram St                    </div>
                            <div className='td'>Arlington, Texas                  </div>
                            <div className='td'>Tennis Court                      </div>
                            <div className='td'><a href="">EDIT</a>               </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/AddManagerForm' style={{ textDecoration: 'none', color: 'white' }}>Add Manager</Link></div>
                        </div>
                    </div> */}

                    {/* <!----------------Gym Manager--------------------> */}

                    {/* <h6>Gym Manager List</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#            </div>
                            <div className='td'>First Name   </div>
                            <div className='td'>Last Name    </div>
                            <div className='td'>Email ID     </div>
                            <div className='td'>DOB          </div>
                            <div className='td'>Govt Id      </div>
                            <div className='td'>Contact No   </div>
                            <div className='td'>Adress Line 1</div>
                            <div className='td'>Adress Line 2</div>
                            <div className='td'>Manager Type </div>
                            <div className='td'>             </div>
                            <div className='td'>             </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>1                                  </div>
                            <div className='td'>Dwight                             </div>
                            <div className='td'>Schrute                            </div>
                            <div className='td'>dwight@gmail.com                   </div>
                            <div className='td'>08/08/1960                         </div>
                            <div className='td'>***-***-6055                       </div>
                            <div className='td'>123-000-5643                       </div>
                            <div className='td'>670 W Abram St                     </div>
                            <div className='td'>Arlington, Texas                   </div>
                            <div className='td'>Gym                                </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                        <div className='tr'>
                            <div className='td'>2                                  </div>
                            <div className='td'>Michael                            </div>
                            <div className='td'>Scott                              </div>
                            <div className='td'>scott@gmail.com                    </div>
                            <div className='td'>02/03/1960                         </div>
                            <div className='td'>***-***-6155                       </div>
                            <div className='td'>123-000-5893                       </div>
                            <div className='td'>671 W Abram St                     </div>
                            <div className='td'>Arlington, Texas                   </div>
                            <div className='td'>Gym                                </div>
                            <div className='td'><a href="">EDIT</a>                </div>
                            <div className='td'><a href="">DELETE</a>                 </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/AddManagerForm' style={{ textDecoration: 'none', color: 'white' }}>Add Manager</Link></div>
                        </div>
                    </div> */}
                </div>    
            </div>
        </main>

        <Chat/>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = "fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div>
        </section>

    </div>
  );
}

// export default buildingManagerDashboard;
