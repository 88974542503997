// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './newSignup.css';
import React, { useState } from 'react'
import axios from 'axios'
import { Header } from '../Header/header';
import { Link, useNavigate } from 'react-router-dom'

export function NewSignup() {
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [dob, setDOB] = useState('')
  const [mobile, setMobile] = useState('')
  const [error, setError] = useState('')
  const [gender, setGender] = React.useState('');
  const [role, setRole] = React.useState('');
  const navigate = useNavigate()
  const handleChange = (event) => {
    setRole(event.target.value);
    console.log(role)
  }

const signupHandle = async (e) => {
  e.preventDefault()
  let config = {method:"POST",email:email,password:password,firstname:firstname,lastname:lastname,gender:gender,dob:dob,mobile:mobile,role:role}
  if (e.target.password.value!=e.target.password1.value){
    alert("Password should match");
    navigate('/NewSignup');
  }else if(e.target.mobile.value.length!=10){
    alert("the mobile number should contain 10 digits");
    navigate('/NewSignup');
  }
  else{
    try {
    const { data } = await axios
        .post(`${apiUrl}/Backend/signup.php`, config)
        .then((response) => {
          console.log(response.data)
          navigate('/Login')
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      setTimeout(() => {
        setError('Invalid credentials...')
      }, 10)
    }
  }
  // try {
  //   const { data } = await axios
  //     .post('${apiUrl}/Backend/signup.php', config)
  //     .then((response) => {
  //       console.log(response.data)
  //       navigate('/Login')
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // } catch (error) {
  //   setTimeout(() => {
  //     setError('Invalid credentials...')
  //   }, 10)
  // }
  
  try {
  const { data } = await axios
      .post(`${apiUrl}/Backend/sendEmail.php`, config)
      .then((response) => {
        console.log(response.data)
        navigate('/Login')
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    setTimeout(() => {
      setError('Invalid credentials...')
    }, 10)
  }
}
  return (
    <div>
    {/* <!-- Start of the header --> */}
    <div className="sub-header1">
        <Header/>
       
        </div>
    <div className="h1-heading">
      <h1>Registration Form</h1>
    </div>
    <div id="container">
        {/* <!-- Code for the REgistration form --> */}
      <div className="form-box">
        <div className='form'>
          <form name="form" action="" method="post" onSubmit={signupHandle}>
            <div className="two-columns">
              <fieldset>
                <label className="form-label" htmlFor="first-name">First name<span className="form-required"> *</span></label>
                <input id="first-name" className="form-input" type="text" onChange={(e) => setFirstName(e.target.value)} name="firstname" placeholder="first name" required/>
              </fieldset>

              <fieldset>
                <label className="form-label" htmlFor="last-name">Last name<span className="form-required"> *</span></label>
                <input id="last-name" className="form-input" type="text" onChange={(e) => setLastName(e.target.value)} name="lastname" placeholder="last name" required/>
              </fieldset>
            </div>

            <fieldset>
              <label className="form-label" htmlFor="dob">DOB<span className="form-required"> *</span></label>
              <input id="dob" className="form-input" type="date" onChange={(e) => setDOB(e.target.value)}  name="dob" placeholder="05/31/2001" required/>
            </fieldset> 

            <fieldset>
              <label className="form-label" htmlFor="gender">Gender<span className="form-required"> *</span></label>
              <input id="gender" className="form-input" type="text" onChange={(e) => setGender(e.target.value)} name="gender" placeholder="Gender" required/>
            </fieldset>
            
            <fieldset>
              <label className="form-label" htmlFor="email">Email address<span>*</span></label>
              <input id="email" className="form-input" type="email" onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Email@gmail.com" required/>
            </fieldset>

            <fieldset>
              <label className="form-label" htmlFor="mobile">Mobile No<span>*</span></label>
              <input id="email" className="form-input" type="text" onChange={(e) => setMobile(e.target.value)} name="mobile" placeholder="Enter mobile no" required/>
            </fieldset>
            
            <fieldset>
              <label className="form-label" htmlFor="password">Password<span className="form-required"> *</span></label>
              <input id="last-name" className="form-input" type="password"  onChange={(e) => setPassword(e.target.value)} name="password" placeholder="#####" required/>
            </fieldset>
            <fieldset>
              <label className="form-label" htmlFor="password">Confirm Password<span className="form-required"> *</span></label>
              <input id="last-name" className="form-input" type="password" name="password1" placeholder="#####" required/>
            </fieldset>

            {/* <!-- Dropdown for role selection --> */}

            <fieldset>
                <label className="form-label" htmlFor="role">Choose a Role<span className="form-required"> *</span></label>
                <select name = "role" className= "form-input" id ="role" value = {role} onChange={handleChange} required>
                  <option value="Resident">Resident</option>
                  <option value="Visitor" >Visitor</option>
                  <option value="Building Manager" >Building Manager</option>
                  <option value="Pool Manager" >Pool Manager</option>
                  <option value="Garden Manager" >Garden Manager</option>
                  <option value="Security Manager" >Security Manager</option>
                </select>
              </fieldset>

            {/* <!-- Add and Cancel buttons --> */}
            <div className="two-columns">
              <button className="form-btn" type="submit">Add</button>
              <button className="form-btn" type="reset">Cancel</button>
            </div>
            
          </form>
        </div>
        {/* <!-- End of the Registration form --> */}
      </div>
    </div>
     {/* <!-- code for the footer icons and FAQs --> */}
     <section className="footer">
        <h3> FAQs </h3>
        {/* <!-- <p> </p> --> */}
    
        <div className ="icons">
            <i className = "fa fa-facebook-f"></i>
            <i className = " fa fa-instagram"></i>
            <i className = "fa fa-address-card"></i>
            <i className = "fa fa-at"></i>
        </div> 
    </section>
        {/* <!-- End of the footer --> */}
  </div>
  );
}

// export default new_signup;
