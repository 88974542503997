// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useContext, useEffect } from 'react';


export function EditEventsForm() {
  const apiurl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const userDat = useContext(UserContext);  

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [user, setUser] = useState('')
    const [role, setRole] = useState('')  
    const [users, setUsers] = useState([]);   
    const {email, FirstName, LastName, DOB, Gender, ContactNo, Address1, GovtId}  = userDat.value

    useEffect(() => {
        return () => {
            getUser();
        if (userDat) {
            console.log("inside security dashboard use effect")
            console.log(userDat.value.email)
            setUser(userDat.value.email)
            setRole(userDat.value.role)
            console.log(userDat.value.FirstName)
        }
        }
    }, [user, role])

    function getUser(id) {
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        axios.get(`${apiurl}/Backend/events.php?id=${id1}`).then(function (response) {
         // axios.get(`https://kjm7989.uta.cloud/Backend/residentgarden.php?id=${id1}`).then(function (response) {
            console.log(response.data);
            setInputs(response.data[0]);
            console.log("inside getuser events edit");
            console.log(inputs);
           
        }).catch(function (error) {
            console.log(error);
          });
    }

    console.log(inputs);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        console.log("Inside submit code");
        axios.put(`${apiurl}/Backend/events.php?id=${id1}&action=edit`, inputs).then(function (response) {
        //axios.post("https://kjm7989.uta.cloud/Backend/events.php", inputs).then(function (response) {
            console.log(response.data);
            if(role=="Building Manager"){
              navigate('/BuildingManagerDashboard');
            }
            else if(role=="Pool Manager"){
              navigate('/PoolManager');
            }
            else if(role=="Garden Manager"){
              navigate('/GardenManager');
            } 
            else{
              navigate('/Login');
            }

        });
    }
  return (
    <div>
      <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
      <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <Header/>
      <div className="h1-heading">
        <h1>Event Details</h1>
      </div>
      <div id="container">
        <div className="form-box">
          <div className='form'>
            <form name="form" action="" method="post" onSubmit={handleSubmit}>
              <div className="two-columns">
                <fieldset>
                  <label className="form-label" for="first-name">Event Name<span className="form-required"> *</span></label>
                  <input value={inputs.EventName} onChange={handleChange} id="first_name" className="form-input" type="text" name="EventName" placeholder="Enter your Name" required />
                </fieldset>

                <fieldset>
                  <label className="form-label" for="visitoremail">Venue<span className="form-required"> *</span></label>
                  <input value={inputs.Venue}  onChange={handleChange} id="visitoremail" className="form-input" type="text" name="Venue"  required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="date">Date<span className="form-required"> *</span></label>
                  <input  value={inputs.Date} onChange={handleChange} id="date" className="form-input" type="date" name="Date" placeholder="05/31/2001" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="in-time">Time<span className="form-required"> *</span></label>
                  <input value={inputs.Time} onChange={handleChange} id="intime" className="form-input" type="time" name="Time" required/>
                </fieldset>

                <fieldset>
                  <label className="form-label" for="out-time">Description<span className="form-required"> *</span></label>
                  <input value={inputs.Description} onChange={handleChange} id="outtime" className="form-input" type="text" name="Description" required/>
                </fieldset>
              </div>

              <div className="two-columns">
                <button className="form-btn" type="submit">Submit</button>
                <button className="form-btn" type="reset">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Chat/>

      <section className="footer">
        <h3> FAQs </h3>
        {/* <!-- <p> </p> --> */}
    
        <div className ="icons">
            <i className = "fa fa-facebook-f"></i>
            <i className = "fa fa-instagram"></i>
            <i className = "fa fa-address-card"></i>
            <i className = "fa fa-at"></i>
        </div>
      </section>
    </div>
  );
}

// export default poolVisitForm;
