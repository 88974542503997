// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './occupantform.css';
import { useState } from 'react';
import { Header } from '../Header/header';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { useEffect } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

export function EditWatchmanShift() {
    const apiurl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
   
    console.log(inputs);
    const id = searchParams.get("id");
    console.log(id)
    useEffect(() => {
        getUser(id);
    }, []);


    function getUser(id) {
        const urlParams = new URLSearchParams(window.location.search);
         const id1 = urlParams.get('id');
       axios.get(`${apiurl}/Backend/watchmanshift.php?id=${id1}`).then(function (response) {
        //axios.get(`https://kjm7989.uta.cloud/Backend/watchman.php?id=${id1}`).then(function (response) {
            console.log(response.data);
            setInputs(response.data[0]);
            console.log("inside getuser watchman edit");
            console.log(inputs);
           
        }).catch(function (error) {
            console.log(error);
          });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(window.location.search);
        const id1 = urlParams.get('id');
        console.log("Inside submit code");
       axios.put(`${apiurl}/Backend/watchmanshift.php?id=${id1}&action=edit`, inputs).then(function (response) {
        //axios.post(`https://kjm7989.uta.cloud/Backend/watchmanshift.php`, inputs).then(function (response) {
            console.log(response.data);
            navigate('/SecurityManager');

        });
    }
  return (
    <div>
        <link rel="stylesheet" href="https://demo.plantpot.works/assets/css/normalize.css"></link>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

        <Header/>
        <div className="h1-heading">
            <h1>Watchman Data Creation</h1>
        </div>
        <div id="container">
            <div className="form-box">
                <div className='form'>
                    <form name="form" action="" method="post" onSubmit={handleSubmit}>
                        <div className="two-columns">

                        <fieldset>
                            <label className="form-label" htmlFor="last-name">Watchman ID<span className="form-required"> *</span></label>
                            <input id="last-name" value={inputs.WatchmanId} className="form-input" type="number" name="WatchmanId"  onChange={handleChange} required/>
                            </fieldset>

                            <fieldset>
                            <label className="form-label" htmlFor="first-name">First name<span className="form-required"> *</span></label>
                            <input id="first-name" value={inputs.Name} className="form-input" type="text" name="Name" placeholder="First name" onChange={handleChange} required/>
                            </fieldset>

                        </div>

                            <fieldset>
                                <label className="form-label" for="duty">Monday<span className="form-required"> </span></label>
                                <input id="duty"  value={inputs.Mon} className="form-input" type="text" name="Mon" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Tuesday<span className="form-required"> </span></label>
                                <input id="duty" value={inputs.Tue}  className="form-input" type="text" name="Tue" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Wednesday<span className="form-required"> </span></label>
                                <input id="duty" value={inputs.Wed} className="form-input" type="text" name="Wed" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Thursday<span className="form-required"> </span></label>
                                <input id="duty" value={inputs.Thur}  className="form-input" type="text" name="Thur" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Friday<span className="form-required"> </span></label>
                                <input id="duty"  value={inputs.Fri} className="form-input" type="text" name="Fri" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Saturday<span className="form-required"> </span></label>
                                <input id="duty" value={inputs.Sat} className="form-input" type="text" name="Sat" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>
                            <fieldset>
                                <label className="form-label" for="duty">Sunday<span className="form-required"> </span></label>
                                <input id="duty" value={inputs.Sun}  className="form-input" type="text" name="Sun" placeholder="Enter In and Out Time"
                                    onChange={handleChange} required/>
                            </fieldset>

                            
                        

                        <div className="two-columns">
                            <button className="form-btn" type="submit">Submit</button>
                            <button className="form-btn" type="reset">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}

            <div className="icons">
                <i className="fa fa-facebook-f"></i>
                <i className="fa fa-instagram"></i>
                <i className="fa fa-address-card"></i>
                <i className="fa fa-at"></i>
            </div>
        </section>
        <Chat/>
    </div>
  );
}

// export default addWatchman;
