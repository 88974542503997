// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function VisitorModuleForSecurity() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiUrl}/Backend/visit.php`).then(function(response) {
            //axios.get(`https://kjm7989.uta.cloud/Backend/visit.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    function deleteVisit(id) {
        axios.delete(`${apiUrl}/Backend/visit.php/${id}/delete`).then(function (response) {
            //axios.delete(`https://kjm7989.uta.cloud/Backend/visit.php/${id}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
    }

  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        
                    
                    <h6>Visitor Log</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Visit Id</div>
                            <div className='td'>Visitor Email ID</div>
                            <div className='td'>Date</div>
                            <div className='td'>In Time:</div>
                            <div className='td'>Out Time:</div>
                            <div className='td'>Vehicle Model</div>
                            <div className='td'>License Plate</div>
                            <div className='td'></div>
                            <div className='td'></div>

                        </div>
                        <div className='tb'>
                            {users.map((user, index) =>
                            <div className='tr' key={index}>
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.visitId}</div>
                                <div className='td'>{user.VisitorEmailId}</div>
                                <div className='td'>{user.VisitDate}</div>
                                <div className='td'>{user.InTime}</div>
                                <div className='td'>{user.OutTime}</div>
                                <div className='td'>{user.VehicleType}</div>
                                <div className='td'>{user.LicensePlate}</div>
                                <div className='td'><Link to={`/EditVisitForSecurity?id=${user.visitId}`} >UPDATE</Link></div>
                                <div className='td'><a href="#"  onClick={() => deleteVisit(user.visitId)}>DELETE</a></div>
                            </div>
                            )}
                    </div>
                        
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            {/* <a href="addViolation.html" type="button" className="occupant-btn">Add Violation</a> */}
                            <div className='a'><Link to='/AddVisitor' style={{ textDecoration: 'none', color: 'white' }}>Log Visit</Link></div>
                        </div>
                    </div>
            
        </main>
    </div>
        
  );
}