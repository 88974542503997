// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function WatchmanShiftModule() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
         axios.get(`${apiUrl}/Backend/watchmanshift.php`).then(function(response) {
           // axios.get(`https://kjm7989.uta.cloud/Backend/watchman.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
            console.log(users)
           
        });
    }
    
    function deleteWatchman(id) {
        axios.delete(`${apiUrl}/Backend/watchmanshift.php/${id}/delete`).then(function (response) {
         // axios.delete(`https://kjm7989.uta.cloud/Backend/watchman.php/${id}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
      }

  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        

                    {/* <!------Manage Watchmen-------------------------> */}

                    <h6>Manage Watchmen Shift</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>ID</div>
                            <div className='td'>First Name</div>
                            <div className='td'>Monday</div>
                            <div className='td'>Tuesday</div>
                            <div className='td'>Wednesday</div>
                            <div className='td'>Thursday</div>
                            <div className='td'>Friday</div>
                            <div className='td'>Saturday</div>
                            <div className='td'>Sunday</div>
                            <div className='td'></div>
                            <div className='td'></div>

                        </div>
                        <div className='tb'>
                            {users.map((user, index) =>
                            <div className='tr' key={index}>
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.Name}</div>
                                <div className='td'>{user.Mon}</div>
                                <div className='td'>{user.Tue}</div>
                                <div className='td'>{user.Wed}</div>
                                <div className='td'>{user.Thur}</div>
                                <div className='td'>{user.Fri}</div>
                                <div className='td'>{user.Sat}</div>
                                <div className='td'>{user.Sun}</div>
                                <div className='td'><Link to={`/EditWatchmanShift?id=${user.Id}`} >UPDATE</Link></div>
                                <div className='td'><a href="#"  onClick={() => deleteWatchman(user.Id)}>DELETE</a></div>
                                {/* <a href="#" onClick={deleteWatchman}>Click me</a> */}
                            </div>
                             )}
                        </div>
                    </div>
                    
                    <div className="button-row">
                        <div className="btn-after-table">
                            {/* <a href="addViolation.html" type="button" className="occupant-btn">Add Violation</a> */}
                            <div className='a'><Link to='/AddWatchmanShift' style={{ textDecoration: 'none', color: 'white' }}>Add Shift</Link></div>
                        </div>
                    </div>
            
        </main>
    </div>
        
  );
}