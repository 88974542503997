// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';

export function OccupantModule() {
   const apiUrl = process.env.REACT_APP_API_URL;
  //const apiUrl = 'htttp://localhost';
  const userDat = useContext(UserContext);     
  const {email, FirstName, LastName, DOB, Gender, ContactNo, Addres1, GovtId}  = userDat.value;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiUrl}/Backend/occupant_table.php?email=${email}`).then(function(response) {
         // axios.get(`https://kjm7989.uta.cloud/Backend/occupant_table.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteOccupant= (id) => {
      axios.delete(`${apiUrl}/Backend/occupant_table.php/${id}/delete`).then(function (response) {
       // axios.delete(`https://kjm7989.uta.cloud/Backend/occupant_table.php/${id}/delete`).then(function (response) {
        console.log(response.data);
        getUsers();
      });
    }

    return (
        <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>

        <h6>Occupant List</h6>
                  
                  <div className='table'>
                      
                      <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>First Name</div>
                            <div className='td'>Last Name</div>
                            <div className='td'>DOB</div>
                            <div className='td'>Gender</div>
                            <div className='td'>Email ID</div>
                            <div className='td'>Mobile No</div>
                            <div className='td'></div>
                            <div className='td'></div>
                      </div>
                      <div className='tb'>
                        {users.map((user, index) =>
                            <div className='tr' key={index}>
                                <div className='td'>{user.Id}</div>
                                <div className='td'>{user.OccupantFName}</div>
                                <div className='td'>{user.OccupantLName}</div>
                                <div className='td'>{user.DOB}</div>
                                <div className='td'>{user.Gender}</div>
                                <div className='td'>{user.Emailid}</div>
                                <div className='td'>{user.ContactNo}</div>
                                <div className='td'><Link to={`/EditOccupantForm?id=${user.Id}`}>EDIT</Link></div>
                                <div className='td'><a href="#"  onClick={() => deleteOccupant(user.Id)}>DELETE</a></div>
                          </div>
                        )}
                        </div>
                  </div>
                  <div className="button-row">
                      <div className="btn-after-table">
                        <div className='a'><Link to='/OccupantForm' style={{ textDecoration: 'none', color: 'white' }}>Add Occupant</Link></div>
                      </div>
                  </div>
        </main>
    </div>
    );
}