// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function GardenResidentAccessModule() {
    const apiurl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios.get(`${apiurl}/Backend/residentgarden.php`).then(function(response) {
           // axios.get(`https://kjm7989.uta.cloud/Backend/residentgarden.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
    const deleteVisit = (id) => {
        axios.delete(`${apiurl}/Backend/residentgarden.php/${id}/delete`).then(function (response) {
           // axios.delete(`https://kjm7989.uta.cloud/Backend/residentgarden.php/${id}/delete`).then(function (response) {
          console.log(response.data);
          getUsers();
        });
      }

    return (
        <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>

        <h6>Resident Garden Visits</h6>
                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>Visit Id</div>
                            <div className='td'>Resident Email ID</div>
                            <div className='td'>Garden No</div>
                            <div className='td'>Date</div>
                            <div className='td'>In Time</div>
                            <div className='td'>NoOfPeople</div>
                            <div className='td'></div>
                            <div className='td'></div>
                        </div>
                        
                        <div className='tb'>
                        {users.map((user, index) =>

                            <div className='tr' key={index}>
                                <div className='td'>{user.Id}</div>
                                <div className='td'>{user.ResidentEmailId}</div>
                                <div className='td'>{user.GardenNo}</div>
                                <div className='td'>{user.Date}</div>
                                <div className='td'>{user.Time}</div>
                                <div className='td'>{user.NoOfPeople}</div>
                                <div className='td'><Link to={`/EditResidentGardenVisit?id=${user.Id}`} >UPDATE</Link></div>
                                <div className='td'><a href="#" onClick={() => deleteVisit(user.Id)}>DELETE</a></div>
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="button-row">
                        <div className="btn-after-table">
                            <div className='a'><Link to='/ResidentGardenForm' style={{ textDecoration: 'none', color: 'white' }}>Register Visit</Link></div>
                        </div>
                    </div>
        </main>
        </div>
    );
    }