// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './log-for.css';
import { Header } from '../Header/header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext/UserContext';
import React, { useState, useEffect, useContext } from 'react';

export function Forgot() {
  const userDat = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [oldpassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [cnfpassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { value, setValue } = useContext(UserContext)
    const apiUrl = process.env.REACT_APP_API_URL;
    //const apiUrl = 'http://localhost';
    const contactHandle = async (e) => {
        e.preventDefault()
        let config = {method:"POST",email:email,oldpassword:oldpassword,password:password}
        
        try {
          const { data } = await axios
            .post(`${apiUrl}/Backend/changepassword.php`, config)
            .then((response) => {
              setValue(null)
              navigate("/Login")
            })
            .catch((error) => {
              console.log(error)
            })
        } catch (error) {
          setTimeout(() => {
            setError('Invalid credentials...')
          }, 10)
        }
      }
  return (
    <body>
        
        <Header/>
       
        <div className="logfor-containers">
            <h1>
                Forgot your password?
            </h1>
            <p>
                Dont worry We have got you !!!
            </p>

           
            <div className="logfor-box">

                {/* <!-- We have divided container in two parts left and right --> */}
                {/* <!-- The left part --> */}
                <div className="logfor-left">
                    {/* <!-- code for forgot password form                   --> */}
                    <form action="" className="form" onSubmit={contactHandle}>
                        <h2>
                            Forgot Password
                        </h2>
                            <div className="logfor-input-group">
                                <label ><b>Email</b></label>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="xyz@emaple.com" className="box"/>
                            </div>
                            <div className="logfor-input-group">
                                <label ><b>Old Password</b></label>
                                <input type="password" onChange={(e) => setOldPassword(e.target.value)} placeholder="old password" className="box"/>
                            </div>
                            <div className="logfor-input-group">
                                <label ><b>New Password</b></label>
                                <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="new password" className="box"/>
                            </div>
                          {/* <!-- Submit button   --> */}
                        <button className='loginbtn' type="submit">UPDATE</button>
                    </form>
                    {/* <!-- End of the forgot-password form --> */}
                </div>

                {/* <!-- Image beside the forgot-password form --> */}
                <div className="logfor-right">
                       {/* <img src="TDG-logos.jpeg" width="500" height="500"/> */}
                </div>         
            </div>
        </div>

        {/* <!-- code for the footer icons and FAQs --> */}
        <section className="footer">
            <h3> FAQs </h3>
            {/* <!-- <p> </p> --> */}
        
            <div className ="icons">
                <i className = "fa fa-facebook-f"></i>
                <i className = " fa fa-instagram"></i>
                <i className = "fa fa-address-card"></i>
                <i className = "fa fa-at"></i>
            </div> 
        </section>
        {/* <!-- End of the footer --> */}

    </body>
  );
}

// export default forgot;
