// import logo from './logo.svg';
/* 
KETA MODI - 1001947989
HELLY SHUKLA - 1002028499
AVISH MODI - 1002069301
RIDHAM TELI - 1002091174 
SMIT JOSHI - 1001946718*/
import './residentdashboard.css';
import { Header } from '../Header/header';
import { Link } from 'react-router-dom';
import Chat from '../Chat/Chat';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export function EventModule() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
         axios.get(`${apiUrl}/Backend/events.php`).then(function(response) {
        //    axios.get(`https://kjm7989.uta.cloud/Backend/events.php`).then(function(response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    
  return (
    <div>
        <link rel="stylesheet" type="text/css" href="https://demo.plantpot.works/assets/css/normalize.css"/>
        <link rel="stylesheet" href="https://use.typekit.net/opg3wle.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <main>
        

                    {/* <!------Manage Events-------------------------> */}

                    <h6>Events</h6>

                    <div className='table'>
                        <div className='tr, th'>
                            <div className='td'>#</div>
                            <div className='td'>Event ID</div>
                            <div className='td'>Event Name</div>
                            <div className='td'>Venue</div>
                            <div className='td'>Date</div>
                            <div className='td'>Time</div>
                            <div className='td'>Description</div>
                            {/* <div className='td'></div>
                            <div className='td'></div> */}
                        </div>
                        <div className='tb'>
                            {users.map((user, index) =>
                            <div className='tr' key={index}>
                                <div className='td'>{index+1}</div>
                                <div className='td'>{user.EventId}</div>
                                <div className='td'>{user.EventName}</div>
                                <div className='td'>{user.Venue}</div>
                                <div className='td'>{user.Date}</div>
                                <div className='td'>{user.Time}</div>
                                <div className='td'>{user.Description}</div>
                                {/* <div className='td'><a href="">UPDATE</a></div>
                                <div className='td'><a href="">CANCEL</a></div> */}
                            </div>
                             )}
                        </div>
                    </div>
                    
                    <div className="button-row">
                        <div className="btn-after-table">
                                <div className='a'><Link to='/VisitorEventRegistration' style={{ textDecoration: 'none', color: 'white' }}>Register For Event</Link></div>
                        </div>
                    </div>
            
        </main>
    </div>
        
  );
}